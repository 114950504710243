import React, { Component } from "react";
import * as moment from 'moment'
//import User from "../User/Index";
import { Link } from "react-router-dom";
//import ShareLink from 'react-facebook-share-link'

export default class SingleQuiz extends Component{

  state = {isHidden: true, isAnswered:false}

  constructor(props) {
        super(props);
        this.allCats = this.props.listItem.Categories.split(";"); 
        this.firstCat = this.allCats[0];
        this.hidden = {
                display:"none"
              };
  };



  componentDidMount(){
    //Sort out the Twitter Share Button
    const anchor = document.createElement("a");
    anchor.setAttribute("class", "twitter-share-button");
    anchor.setAttribute("data-hashtags", "trivia");
    anchor.setAttribute("data-url", "https://trivologist.com/" + moment(this.props.listItem.DateUsed).format("DD-MM-YYYY"));
    anchor.setAttribute("data-text", this.props.listItem.Question);
    anchor.setAttribute("data-related", "trivologist");
    anchor.setAttribute("data-show-count", "false");
    anchor.setAttribute("href", "https://twitter.com/share?ref_src=twsrc%5Etfw");
    anchor.innerHTML = "<i className='fab fa-twitter'></i>Tweet";
    document.getElementsByClassName("twitter-embed " + this.props.listItem.id)[0].appendChild(anchor);

    const script = document.createElement("script");
    script.setAttribute("src", "https://platform.twitter.com/widgets.js");
    document.getElementsByClassName("twitter-embed " + this.props.listItem.id)[0].appendChild(script);

  }
       
    //Adds the ability to share the link
      shareLinkWithShareDialog() {
document.getElementById("shareButton").style.display = "none";
document.getElementById("loading").style.display = "inline";

var URL = "https://trivologist.com/" + moment(this.props.listItem.DateUsed).format("DD-MM-YYYY");

console.log("Item URL" + URL);

        window.FB.ui(
                {
                  method: 'share',
                  href: URL,
                  quote: this.props.listItem.Question
                },
                // callback
                function(response) {
                  if (response && !response.error_message) {
                    console.log('Posting completed.');
                  } else {
                     console.log('Error while posting.');
                  }
                  document.getElementById("shareButton").style.display = "inline";
document.getElementById("loading").style.display = "none";
                }
                
              );

};




  render(){
    

   return(
      <div className={"m-2 my-2 quizContainer " + this.firstCat}>
        <Link to={"/Quiz/" + this.props.listItem.Stub}>

<div className="row">
      <div className="col-lg-3 col-xs-12 col-sm-6">
      <img className="quizImage" src={this.props.listItem.MediaURL} alt={this.props.listItem.MediaText} ></img>
  <p className="imgByline">{this.props.listItem.MediaText}</p>
      </div>

<div className="col-lg-7 col-xs-12 col-sm-6 text-left quizText">



<p className="quizHeading">
              <strong>{this.props.listItem.Title}</strong>
            
            </p>  
            <div className="quizDetails">

              {this.props.listItem.Description}

</div>
</div>

<div className="col-sm-12 col-lg-2">
  
  <span className="catContainer">
    <div className="catIcon">
{this.props.listItem.Categories.startsWith('Entertainment') &&
            <i className="fas fa-compact-disc"></i>
   }
    {this.props.listItem.Categories.startsWith('Sport') &&
            <i className="fas fa-volleyball-ball"></i>
   }
    {this.props.listItem.Categories.startsWith('Technology') &&
            <i className="fas fa-microchip"></i>
   }
    {this.props.listItem.Categories.startsWith('History') &&
            <i className="fas fa-landmark"></i>
   }
    {this.props.listItem.Categories.startsWith('Art') &&
            <i className="fas fa-palette"></i>
   }
    {this.props.listItem.Categories.startsWith('Food') &&
            <i className="fas fa-drumstick-bite"></i>
   }
    {this.props.listItem.Categories.startsWith('Geography') &&
            <i className="fas fa-globe-asia"></i>
   }
 {this.props.listItem.Categories.startsWith('Science') &&
            <i className="fas fa-flask"></i>
   }
    {this.props.listItem.Categories.startsWith('General') &&
            <i className="fas fa-lightbulb"></i>
   }
<br />
  {this.firstCat}
  </div>
        </span>
   
  
</div>


</div>
  
<div className="shareRow">



<button id="shareButton" className="btn-fbshare"  onClick={this.shareLinkWithShareDialog.bind(this)}><i className="fab fa-facebook-f"></i>Share</button>




<i className="fas fa-spinner fa-spin" id="loading" style={this.hidden}></i>


      <span className={"twitter-embed " + this.props.listItem.id}></span>


</div>


</Link>
</div>
   );
  }
}
