import {BehaviorSubject} from "rxjs";
//import axios from 'axios.js';
import history from "./history.js";
//const history = require('../services/history.js')
//import history from '../services/history.js';
//import { authenticationService } from '../services/Authentication'
///import { connectLogger } from 'log4js';

const baseUrl = `${process.env.REACT_APP_API_URL}/accounts`;
const accountSubject = new BehaviorSubject(null);


const accountsKey = 'react-facebook-login-accounts';
let accounts = JSON.parse(localStorage.getItem(accountsKey)) || [];


export const accountService = {
    login,
    apiAuthenticate,
    logout,
    getAll,
    getById,
    account: accountSubject.asObservable(),
    get accountValue () { return accountSubject.value; }
};

async function login() {
    // login with facebook then authenticate with the API to get a JWT auth token
    console.log("HELLLOOOOO!");
    const { authResponse } = await new Promise(window.FB.login);
    console.log("HELLLOO2");
    if (!authResponse) 
    {
        return "no authresp found";
    }
    var recievedAccount = await apiAuthenticate(authResponse.accessToken);
    // get return url from location state or default to home page
    console.log("Second Account")
    console.log(recievedAccount)
    return recievedAccount
}

async function apiAuthenticate(accessToken) {
    // authenticate with the api using a facebook access token,
    // on success the api returns an account object with a JWT auth token

    console.log("ey");

    //const response = await authenticate(accessToken);
    
    console.log("Doing the FB LOGIN!")
    console.log(accessToken)
    try{
        let acct = {}
        await fetch("https://graph.facebook.com/v16.0/me?fields=name,email,picture&access_token=" + accessToken)
        .then(response => response.json())
        .then(data => {
                if (data.error) {
                        console.log("Promise Rejected")
                        return Promise.reject(data.error.message);
                }
                acct = accounts.find(x => x.facebookId === data.id);
                        if (!acct) 
                           {
                            // create new account if first time logging in
                            acct = {
                                id: data.id,
                                facebookId: data.id,
                                name: data.name,
                                email: data.email,
                                picture: data.picture.data.url,
                                extraInfo: `This is some extra info about ${data.name} that is saved in the API`
                            }
                            console.log("Account")
                            console.log(acct)
                            accounts.push(acct);
                            localStorage.setItem(accountsKey, JSON.stringify(accounts));
                            }           
                    console.log("Returning")
                    console.log(acct);
                    console.log("bee");
                    accountSubject.next(acct);
                    console.log("sea")
                    //startAuthenticateTimer();
                    
                    });
                    return acct;
                }
                catch(error)
                {
                    console.log(error)
                    return {"Error": error}
                }

}
/*
function authenticate(accessToken) {
    
    return account;
  })
    }
    catch(error)
    {
        console.log("Error in Authenticate method")
        return {"Error": error}
    }
}
/*
function generateJwtToken(account) {
    // create token that expires in 15 minutes
    const tokenPayload = { 
        exp: Math.round(new Date(Date.now() + 15*60*1000).getTime() / 1000),
        id: account.id
    }
    return `fake-jwt-token.${btoa(JSON.stringify(tokenPayload))}`;
}
*/
function logout() {
    // revoke app permissions to logout completely because FB.logout() doesn't remove FB cookie
    window.FB.api('/me/permissions', 'delete', null, () => window.FB.logout());
    //stopAuthenticateTimer();
    accountSubject.next(null);
    history.push('/login');
}

function getAll() {
    
    return fetch(baseUrl)
        .then(response => response.data);
}

function getById(id) {
    return fetch(`${baseUrl}/${id}`)
        .then(response => response.data);
}

/*
function newAccountId() {
    return accounts.length ? Math.max(...accounts.map(x => x.id)) + 1 : 1;
}
*/


/*Fuck Axios
async function update(id, params) {
    const response = await axios.put(`${baseUrl}/${id}`, params);
    let account = response.data;
    // update the current account if it was updated
    if (account.id === accountSubject.value?.id) {
        // publish updated account to subscribers
        account = { ...accountSubject.value, ...account };
        accountSubject.next(account);
    }
    return account;
}
*/
/*Deleted as I am not using axios
async function _delete(id) {
    await axios.delete(`${baseUrl}/${id}`);
    if (id === accountSubject.value?.id) {
        // auto logout if the logged in account was deleted
        logout();
    }
}
*/




// helper methods

//let authenticateTimeout;
/*
function startAuthenticateTimer() {
    // parse json object from base64 encoded jwt token
    const jwtToken = JSON.parse(atob(accountSubject.value.token.split('.')[1]));
    console.log(jwtToken)
    // set a timeout to re-authenticate with the api one minute before the token expires
    const expires = new Date();//jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - (60 * 1000);
    const { accessToken } = window.FB.getAuthResponse();
    authenticateTimeout = setTimeout(() => apiAuthenticate(accessToken), timeout);
}

function stopAuthenticateTimer() {
    // cancel timer for re-authenticating with the api
    clearTimeout(authenticateTimeout);
}*/

/*Deleted due to axios being fucked
function unauthorized() {
    setTimeout(() => {
        const response = { status: 401, data: { message: 'Unauthorized' } };
        
        // manually trigger error interceptor
        const errorInterceptor = axios.interceptors.response.handlers[0].rejected;
        errorInterceptor({ response });
    }, 500);
}
*/
