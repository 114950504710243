import React, {Component} from 'react';
import CONSTANTS from "../../constants";
import SingleQuizNav from "./SingleQuizNav"
import {Helmet} from "react-helmet";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
export default class QuizNav extends Component  {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      sort: "Count",
      heading: ""
    };
    

  }
  componentWillMount() {

    var count = this.props.Number;
    if(!count)
    {
      count=10
    }
    var random = this.props.Random
    if(random)
    {
      count = count+"/Random";
    }
    //Get all List Items
    fetch("/api/GetQuizzes/"+count)
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then(result => this.setState({ list: result.data }))
      .catch(error =>
        this.props.throwError(`${CONSTANTS.ERROR_MESSAGE.LIST_GET} ${error}`)
      );


      if (random) {
        this.setState({heading: <span className="quizNavHeading">More brain bending quizzes:</span>});
      } else {
        this.setState({heading: <span className="quizNavHeading">Some of our latest mind melting quizzes:</span>});
      }


  }

  //This all happens once the See Answers button is clicked
  toggleSort = function(e){

      if(this.state.sort === "Count")
      {
      this.setState({sort: "Name"});
    }
    else
    {
      this.setState({sort: "Count"});

    }
      
    this.getData();
  }

  render() {
    const {
      list
    } = this.state;
    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 1140 },
        items: 4
      },
      tablet: {
        breakpoint: { max: 1140, min: 721 },
        items: 2
      },
      mobile: {
        breakpoint: { max: 720, min: 0 },
        items: 1
      }
    };


  return (

    
    <div className="col-md-10 offset-md-1 order-md-2" >
      {this.state.heading}

      <Carousel
  swipeable={true}
  draggable={false}
  showDots={false}
  responsive={responsive}
  ssr={true} // means to render carousel on server-side.
  infinite={true}
  autoPlay={this.props.deviceType !== "mobile" ? false : false}
  autoPlaySpeed={3000}
  keyBoardControl={true}
  customTransition="all .5"
  transitionDuration={500}
  containerClass="carousel-container"
  removeArrowOnDeviceType={["tablet", "mobile"]}
  deviceType={this.props.deviceType}
  dotListClass="custom-dot-list-style"
  itemClass="carousel-item-padding-40-px"
  centerMode={true}
>

    {list.map(listItem => (
      <div key={listItem.id} className={this.firstCat}> 
     {document.body.className = ""}
     {document.body.classList.add(listItem.Categories.includes(';') ? listItem.Categories.substring(0,listItem.Categories.indexOf(';')).replace(/ /g,'').replace('&','') : listItem.Categories.replace(/ /g,'').replace('&',''))}

    <SingleQuizNav
       key={listItem.id}
       listItem={listItem}
       location={this.props.location}
     />

     </div>
   ))}
                 
        </Carousel>
      <Helmet>
      <meta name="description"  content="Quizzes" />
      <meta property="og:title" content="Trivologist - Learn something new every day" />
      <meta property="og:image" content="https://trivologist.com/1024.png" />
      <meta property="og:description"  content="Quizzes" />
    </Helmet>
   </div>
  );
}
}
