import React, { Component } from "react";
//import { setDate } from "date-fns";

export default class Countdown extends Component{

  state = {isHidden: false}
  toggleHidden = ()=>this.setState((prevState)=>({isHidden: !prevState.isHidden}))
  componentDidMount() {
// Set the date we're counting down to





// Update the count down every 1 second
var x = setInterval(function() {

  // Get today's date and time
  var now = new Date();
  var today = new Date();
  today.setDate(new Date().getDate()+1);
var tomorrowUTC = new Date(today ).toUTCString()
 tomorrowUTC = new Date(tomorrowUTC)
var tomorrowUTCDate = tomorrowUTC.getFullYear() + "-" + ("0" + (tomorrowUTC.getMonth()+1)).slice(-2) + "-" + ("0" + (tomorrowUTC.getDate())).slice(-2) + "T00:00:00Z";

tomorrowUTCDate = new Date(tomorrowUTCDate)


var timeInt = tomorrowUTCDate.getTime()
//var timeIntHere = timeInt - (now.getTimezoneOffset() *60 * 1000)
var dateHereinUTC = new Date(timeInt);


var distance =  dateHereinUTC - now
    
  // Time calculations for days, hours, minutes and seconds
  //var days = Math.floor(distance / (1000 * 60 * 60 * 24));
  var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  var seconds = Math.floor((distance % (1000 * 60)) / 1000);
    

  // Output the result in an element with id="demo"
  if(document.getElementById("clock"))
  {
  document.getElementById("clock").innerHTML = hours + "h "
  + minutes + "m " + seconds + "s ";
  }
    
  // If the count down is over, write some text 
  if (distance < 0) {
    clearInterval(x);
    document.getElementById("clock").innerHTML = "You are now in the future!";
  }
}, 1000);
  }
  render(){
   return(
           <div className="newDate">
                          <i className={"far fa-hourglass arrow hourGlassIcon"} ></i>

                Next question in:<br /><span id="clock"></span>
        </div>
   )
  }
 }