import React, { Component } from "react";
      


export default class SelectableQuestion extends Component{





  constructor(props) {
        super(props);
        this.state = {
          questions: []
        };
        this.handleChange = this.props.handleChange;
  };





  render(){
    

   return(
      <div className={"m-2 my-2"}>
      <div >
        <input type="checkbox" id={this.props.listItem.id}
                  onChange={this.handleChange}
                  value={this.props.listItem.id}
                  name="id"
               ></input>
            {this.props.listItem.Question}
            <br />
          <span className="italics"> Categories: {this.props.listItem.Categories} 
          </span>
           {/*{this.props.listItem.QuestionType}*/}
            </div>  

      </div>
   )
  }

  
 }