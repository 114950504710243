import React from "react";
import styles from "./footer.module.css";
import  {Link} from "react-router-dom";


export default function Footer() {
  return (
    <footer className={styles.footer}>
      <div className="container-fluid">
        <div className="row justify-content-around">
          <div className="col-4">
            <h5 className={styles.title}>Trivologist</h5>
          </div>
          <div className="col-4 center footerIcons">
            <a href="https://www.facebook.com/Trivologist/" className="btn-fblink"><i className="fab fa-facebook"></i></a>
            <a href="https://twitter.com/trivologist" className="btn-twlink"><i className="fab fa-twitter"></i></a>
            
          </div>
          <div className="col-4 text-right">
           {
                <Link to="/Privacy" className={styles.footerlink}>
                  Privacy Policy
                  </Link>

            /*  <li>
                <a className={styles.footerlink} href="/">
                  Example Link
                </a>
              </li>
              <li>
                <a className={styles.footerlink} href="/">
                  Example Link
                </a>
              </li>
  </ul>*/}
          </div>
        </div>
      </div>
      <div className="footerText"><a href="https://www.buymeacoffee.com/trivologist" >Please shout us a coffee to help keep the questions coming</a>
      </div>
    </footer>
  );
}
