import React from "react";


export default function Contact() {
  return (
    <div className="pageContent contact">
  
      <h2 className="heading text-center">You can hit up the Trivologist team at:</h2>
      <br />
      <br />
<div className="row align-middle text-center">

  <div className="col-md-3 col-6 contactIcon"><a rel="noopener noreferrer" href="https://www.facebook.com/Trivologist/" target="_blank"><i className="fab fa-facebook"></i><br />Facebook</a></div>
  <div className="col-md-3 col-6 contactIcon"><a rel="noopener noreferrer" href="http://m.me/Trivologist" target="_blank"><i className="fab fa-facebook-messenger"></i><br />Messenger</a></div>
  <div className="col-md-3 col-6 contactIcon"><a rel="noopener noreferrer" href="https://twitter.com/Trivologist/" target="_blank"><i className="fab fa-twitter"></i><br />Twitter</a></div>
  <div className="col-md-3 col-6 contactIcon"><a rel="noopener noreferrer" href="mailto:trivologist@gmail.com" target="_blank"><i className="far fa-envelope"></i><br />Email</a></div>
</div>

    </div>
  );
}
