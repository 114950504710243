import React, {Component} from 'react';
import SingleItem from "../GetToday/SingleItem"


export default class Categories extends Component  {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      sort: "Count"
    };
  }



  componentWillMount() {
    //Get all List Items
    fetch("/api/list")
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then(
        result => {
            this.setState({ list: result.data });
        }
        )
      .catch(error =>
        this.props.throwError(error.message)
      );

      

  }

  //This all happens once the See Answers button is clicked
  toggleSort = function(e){

      if(this.state.sort === "Count")
      {
      this.setState({sort: "Name"});
    }
    else
    {
      this.setState({sort: "Count"});

    }
      
    this.getData();
  }

  render() {
    const {
      list
    } = this.state;



  return (
    <div className="col-md-10 offset-md-1 order-md-2" >
    {list.map(listItem => (

      <div className={"question "} key={listItem.id}> 
      {console.log(listItem)}

     {document.body.className = ""}
     {document.body.classList.add(listItem.Categories.includes(';') ? listItem.Categories.substring(0,listItem.Categories.indexOf(';')).replace(/ /g,'').replace('&','') : listItem.Categories.replace(/ /g,'').replace('&',''))}

    <SingleItem
       key={listItem.id}
       listItem={listItem}
       location={this.props.location}
       
     />
     </div>
   ))}
   </div>
  );
}
}
