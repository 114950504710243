import React, { Component } from "react";
import SingleItem from "../GetToday/SingleItem";
import CONSTANTS from "../../constants";
import WarningMessage from "../WarningMessage";
import {Helmet} from "react-helmet";

export default class Categories extends Component  {

  constructor(props) {
    super(props);
    this.state = {
      list: [],
      WarningMessageOpen: false,
      WarningMessageText: "",
    };
  }
  
  componentDidMount() {

  var { cat } = this.props.match.params;
    fetch("/api/GetList/"+cat)  
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then(
        result => 
        {
            if(result.length === 0)
          {
            console.log("No items");

          }
          
            this.setState({ list: result.data})
          }
      )
      .catch(error =>
        this.setState({
          WarningMessageOpen: true,
          WarningMessageText: `${CONSTANTS.ERROR_MESSAGE.LIST_GET} ${error}`
        })
      );
}


handleWarningClose() {
  this.setState({
    WarningMessageOpen: false,
    WarningMessageText: ""
  });
}

      //This happens when any anwswer button is clicked
checkAnswer = function(Answer, isCorrect){
    console.log("You got " + Answer + " " + isCorrect);
}


  render() { 
    const {
      list,
      WarningMessageOpen,
      WarningMessageText
    } = this.state;


    return(
<main id="mainContent" className={'Container'}> 
<div className="row m-3 my-2">



      <div className="col-md-10 offset-md-1" > 
          {list.map(listItem => (
       <div className={"question "} key={listItem.id}> 


      {document.body.className = ""}
      {document.body.classList.add(listItem.Categories.includes(';') ? listItem.Categories.substring(0,listItem.Categories.indexOf(';')).replace(/ /g,'').replace('&','') : listItem.Categories.replace(/ /g,'').replace('&',''))}
     <SingleItem
        key={listItem.id}
        listItem={listItem}
        location={this.props.location}
        manageAnswer={this.checkAnswer}
        
      />
      </div>
    ))}

    </div>


</div>
              <WarningMessage
            open={WarningMessageOpen}
            text={WarningMessageText}
            onWarningClose={this.handleWarningClose}
          />

<Helmet>
      <meta name="description"  content={this.props.match.params} />
      <meta property="og:title" content="Trivologist - Learn something new every day" />
<meta property="og:image" content="https://trivologist.com/1024.png" /> 
      <meta property="og:description"  content={this.props.match.params}  />
    </Helmet>
    </main>
    );
  }
}


