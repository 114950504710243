import React, { Component } from "react";
//import User from "../User/Index";
import { Link } from "react-router-dom";
import {Card} from 'react-bootstrap'
//import {Button} from 'react-bootstrap'

export default class SingleQuizNav extends Component{

  state = {isHidden: true, isAnswered:false}

  constructor(props) {
        super(props);
        this.allCats = this.props.listItem.Categories.split(";"); 
        this.firstCat = this.allCats[0];
        this.hidden = {
                display:"none"
              };
  };



  componentDidMount(){


  }
       



  render(){
    

   return(
      <div>
        <Link to={"/Quiz/" + this.props.listItem.Stub}>

        <Card>
  <Card.Img className="quizImageNav"  variant="top" src={this.props.listItem.MediaURL} alt={this.props.listItem.MediaText} />
  <Card.Body>
          
  <div className={"catContainer " + this.firstCat}>
    <div className="catIcon">
{this.props.listItem.Categories.startsWith('Entertainment') &&
            <i className="fas fa-compact-disc"></i>
   }
    {this.props.listItem.Categories.startsWith('Sport') &&
            <i className="fas fa-volleyball-ball"></i>
   }
    {this.props.listItem.Categories.startsWith('Technology') &&
            <i className="fas fa-microchip"></i>
   }
    {this.props.listItem.Categories.startsWith('History') &&
            <i className="fas fa-landmark"></i>
   }
    {this.props.listItem.Categories.startsWith('Art') &&
            <i className="fas fa-palette"></i>
   }
    {this.props.listItem.Categories.startsWith('Food') &&
            <i className="fas fa-drumstick-bite"></i>
   }
    {this.props.listItem.Categories.startsWith('Geography') &&
            <i className="fas fa-globe-asia"></i>
   }
 {this.props.listItem.Categories.startsWith('Science') &&
            <i className="fas fa-flask"></i>
   }
    {this.props.listItem.Categories.startsWith('General') &&
            <i className="fas fa-lightbulb"></i>
   }
  </div>
  </div>
    <Card.Title>{this.props.listItem.Title}</Card.Title>
    <Card.Text>
    {this.props.listItem.Byline}
  </Card.Text>


  </Card.Body>
</Card>
{/* 
<div className="row">
      <div className="col-sm-5 col-xs-6 col-md-3 col-lg-12 col-xl-6" >
      <img className="quizImageNav" src={this.props.listItem.MediaURL} alt={this.props.listItem.MediaText} ></img>
      </div>

<div className="col-sm-5 col-xs-6 col-md-7 col-lg-12 col-xl-6 text-left quizText">


<p className="quizNavHeading">
              <strong>{this.props.listItem.Title}</strong>
            
            </p>  
            <div className="quizByline">

{this.props.listItem.Byline}

</div>
</div>
<div className="col-lg-12 col-sm-2 col-xs-12 col-md-2">
  
  <span className="catContainer">
  
        </span>
   
  
</div>


</div>
*/}

</Link>
</div>
   );
  }
}
