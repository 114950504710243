import React, { Component } from "react";
import Categories from "./Categories";
import AllQuestions from "./AllQuestions";
import AllQuizzes from "./AllQuizzes";
import WarningMessage from "../WarningMessage";
import CONSTANTS from "../../constants";
import NewQuiz from "./NewQuiz";
import { authenticationService } from '../../services/Authentication.js'


export default class Admin extends Component {
  constructor(props) {
    super(props);
    this.onAddQuiz = this.onAddQuiz.bind(this);
    this.handleWarningClose = this.handleWarningClose.bind(this);
    this.handleWarningOpen = this.handleWarningOpen.bind(this);
    this.cu = authenticationService.currentUserValue;
    this.state = {
      list: [],
      tab: "allquizzes",
      WarningMessageOpen: false,
      WarningMessageText: "",
      currentUser: this.cu,
      isAdmin: false,
      isContributor: false,
    };

    
  }

  toggleSort = function(e, tab){
    this.setState({tab: tab});

}

handleWarningOpen(error){
  this.setState({
    WarningMessageOpen: true,
    WarningMessageText: error
  });
}


handleWarningClose() {
  this.setState({
    WarningMessageOpen: false,
    WarningMessageText: ""
  });
}

  componentDidMount() {
    //Get all List Items
    fetch(CONSTANTS.ENDPOINT.LIST)
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then(result => this.setState({ list: result.data }))
      .catch(error =>
        this.setState({
          WarningMessageOpen: true,
          WarningMessageText: `${CONSTANTS.ERROR_MESSAGE.LIST_GET} ${error}`
        })
      );
  }
    
  onAddQuiz(title, description, categories, author,  mediaurl, mediatext, quiztype, questions, samplefile, status, byline) {
    // Warning Pop Up if the user submits an empty message
    if (!title) {
      this.setState({
        WarningMessageOpen: true,
        WarningMessageText: CONSTANTS.ERROR_MESSAGE.LIST_EMPTY_MESSAGE
      });
      return;
    }

    fetch("/api/AddQuiz", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        Title: title,
        Description: description,
        Categories: categories,
        MediaURL: mediaurl,
        MediaText: mediatext,
        QuizType: quiztype,
        UserEmail: author,
        Questions: questions,
        SampleFile: samplefile,
        Status: status,
        Byline: byline
      })
    })
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then(result =>
        this.setState(prevState => ({
          list: [result.data, ...prevState.list]
        }))
      )
      .catch(error =>
        this.setState({
          WarningMessageOpen: true,
          WarningMessageText: error.message
        })
      );
  }


  UNSAFE_componentWillMount(){

    authenticationService.currentUser.subscribe(x => this.setState({
      currentUser: x,
      isAdmin: x && x.role === "admin",
      isContributor: x && x.role === "contributor"
  }));
  
  
  }


  render() {

    const {
      list,
      isAdmin, 
      WarningMessageOpen,
      WarningMessageText
    } = this.state;
    return (
      <main id="mainContent" className="container">
        <div className="row">
          <div className="col-12 mt-5 p-0">
            <h3>Admin</h3>

            <p>Total Questions: {list.length} <br />
            Publishable: {list.filter(item => item.Status === "Published").length} - {list.filter(item => item.DateUsed !== null && item.Status === "Published").length} used  -  {list.filter(item => item.DateUsed === null && item.Status === "Published").length} remaining</p>

                      </div>
          <button onClick={(e) => {
     this.toggleSort(e, "questions")
}}>Questions</button>
          <button onClick={(e) => {
     this.toggleSort(e, "categories")
}}>Categories</button>
<button onClick={(e) => {
     this.toggleSort(e, "newquiz")
}}>New Quiz</button>
{isAdmin &&
<button onClick={(e) => {
     this.toggleSort(e, "allquizzes")
}}>All Quizzes</button>
  }
          {this.state.tab === "categories" && 
          <div className="col-12 p-0">

          <Categories />
          </div>
         
          }
           {this.state.tab === "newquiz" && 
          <div className="col-12 p-0">

          <NewQuiz throwError={this.handleWarningOpen} onAddQuiz={this.onAddQuiz}  currentUser={this.state.currentUser}/>
          </div>
          }
           {this.state.tab === "allquizzes" && 
          <div className="col-12 p-0">

          <AllQuizzes throwError={this.handleWarningOpen} currentUser={this.state.currentUser}/>
          </div>
          }
           {this.state.tab === "questions" &&
          <div className="col-12 p-0">

          <AllQuestions throwError={this.handleWarningOpen} currentUser={this.state.currentUser}/>
          </div>
          }
          <WarningMessage
            open={WarningMessageOpen}
            text={WarningMessageText}
            onWarningClose={this.handleWarningClose}
          />
        </div>
      </main>
    );
  }
}
