const CONSTANTS = {};

CONSTANTS.ERROR_MESSAGE = {};

CONSTANTS.ERROR_MESSAGE.LIST_DELETE = "Request to delete list item failed:";
CONSTANTS.ERROR_MESSAGE.LIST_ADD = "Request to add list item failed:";
CONSTANTS.ERROR_MESSAGE.LIST_GET = "Request to get list items failed:";
CONSTANTS.ERROR_MESSAGE.LIST_EMPTY_MESSAGE = "Please enter a valid message";

CONSTANTS.ENDPOINT = {};

CONSTANTS.ENDPOINT.LIST = "/api/list";
CONSTANTS.ENDPOINT.GETCATLIST = "/api/GetList";
CONSTANTS.ENDPOINT.CATEGORIES = "/api/Categories";
CONSTANTS.ENDPOINT.GETTODAY = "/api/getToday";
CONSTANTS.ENDPOINT.TWEET = "/api/Tweet";
CONSTANTS.ENDPOINT.LOGIN = "/api/Login";

export default CONSTANTS;
