import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import "./App_New.scss";
import TrivNavBar from "./components/TrivNavBar";
import Footer from "./components/Footer";
import List from "./components/List";
import Admin from "./components/Admin";
import EditItem from "./components/List/EditItem";
import GetToday from "./components/GetToday";
import Categories from "./components/Categories";
import Quiz from "./components/Quiz"
import Home from "./components/Home"
import Shop from "./components/Shop"
import FullQuiz from "./components/Quiz/FullQuiz"
import Profile from "./components/Profile"
import Privacy from "./components/Privacy";
import DataDeletion from "./components/Privacy/DataDeletion";
import About from "./components/About";
import Contact from "./components/Contact";
import { PrivateRoute } from "./components/PrivateRoute";
import myhistory from './services/history';
import moment from "moment";

import packageJson from "../package.json";
import withClearCache from "./ClearCache";
import initFacebookSdk from './services/init-FB-sdk';



export const getBuildDate = (epoch) => {
  const buildDate = moment(epoch).format("DD-MM-YYY HH:MM");
  return buildDate;
};

class App extends Component {

  componentDidMount(){


  }
  


  
  render() {
  const ClearCacheComponent = withClearCache(App);
    console.log("Clear Cache ", ClearCacheComponent)
    initFacebookSdk()
    return (
<Router myhistory={myhistory}>
        <div>
        <ClearCacheComponent />
        <TrivNavBar />
        <Switch>
          <PrivateRoute path = "/List" component = { List }  roles={["admin", "contributor"]}  />
          <PrivateRoute path = "/Admin" component = { Admin }  roles={["admin", "contributor"]}  />

          <Route path = "/Privacy" component = { Privacy } />
          <Route path = "/DataDeletion" component = { DataDeletion } />
          <Route path = "/About" component = { About } />
          <Route path = "/Contact" component = { Contact } />
          <Route path = "/GetToday/:date" render={(props) => <GetToday {...props}/>} />
          <Route path = "/GetToday/" render={(props) => <GetToday {...props}/>} />
          <Route path = "/Categories/:cat" component = { Categories } />
          <Route path = "/GetToday" component = { GetToday } />
          <PrivateRoute path = "/Edit/:id" component = { EditItem } roles={["admin"]} />
          <Route path = "/Quiz/:id" component = { FullQuiz }/>
          <Route path = "/Quiz/" component = { Quiz }/>
          <Route path = "/Shop/" component = { Shop }/>
          <PrivateRoute path = "/Profile/" component = { Profile } />

          <Route exact path = "/:date" render={(props) => <Home {...props}/>} />
          <Route path = "/" render={(props) => <Home {...props}/>} />
        </Switch>
        <Footer />
        <p style={{display:"none"}}>Build date: {getBuildDate(packageJson.buildDate)}</p>
        </div>
      </Router>
      
    );
  }
}

export default App;
