import React, {Component} from 'react';
import CONSTANTS from "../../constants";
import SingleQuiz from "../Quiz/SingleQuiz"
import {Helmet} from "react-helmet";
import WarningMessage from "../WarningMessage";


export default class Quiz extends Component  {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      sort: "Count",
      WarningMessageOpen: false,
      WarningMessageText: "",
    };
    this.handleWarningClose = this.handleWarningClose.bind(this);
    this.handleWarningOpen = this.handleWarningOpen.bind(this);
  }

  handleWarningOpen(error){
    this.setState({
      WarningMessageOpen: true,
      WarningMessageText: error
    });
  }
  
  
  handleWarningClose() {
    this.setState({
      WarningMessageOpen: false,
      WarningMessageText: ""
    });
  }

  componentWillMount() {
    //Get all List Items
    fetch("/api/GetQuizzes")
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then(result => {
        this.setState({ list: result.data })
        console.log(result)
        console.log("Quizresult")
      }
        )
      .catch(error =>
        this.handleWarningOpen(`${CONSTANTS.ERROR_MESSAGE.LIST_GET} ${error}`)
      );





  }

  //This all happens once the See Answers button is clicked
  toggleSort = function(e){

      if(this.state.sort === "Count")
      {
      this.setState({sort: "Name"});
    }
    else
    {
      this.setState({sort: "Count"});

    }
      
    this.getData();
  }

  render() {
    const {
      list
    } = this.state;



  return (
    <div className="col-md-10 offset-md-1 order-md-2 contentContainer" >
        <h4>All Quizzes</h4>
{
//Check out some of our quizzes,
} 




    {list.map(listItem => (

      <div className={"question "} key={listItem.id}> 
      {console.log(listItem)}

     {document.body.className = ""}
     {document.body.classList.add(listItem.Categories.includes(';') ? listItem.Categories.substring(0,listItem.Categories.indexOf(';')).replace(/ /g,'').replace('&','') : listItem.Categories.replace(/ /g,'').replace('&',''))}

    <SingleQuiz
       key={listItem.id}
       listItem={listItem}
       location={this.props.location}
       throwError={this.handleWarningOpen}
     />
     </div>
   ))}
      <Helmet>
      <meta name="description"  content="Quizzes" />
      <meta property="og:title" content="Trivologist - Learn something new every day" />
      <meta property="og:image" content="https://trivologist.com/1024.png" />
      <meta property="og:description"  content="Quizzes" />
    </Helmet>

    <WarningMessage
open={this.WarningMessageOpen}
text={this.WarningMessageText}
onWarningClose={this.handleWarningClose}
/>
   </div>


  );
}
}
