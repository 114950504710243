import React from "react";

export default function SingleProduct(props) {
  const { listItem } = props;
  return (
    
    <div className="col-12 mb-3 border">
      <div className="row">
        <div className="col-4">
          <p className="mt-3"><img src={listItem.Image} alt="Product" />{listItem.ProductName}</p>
        </div>
        <div className="col-4">
          <p className="mt-3">{listItem.ProductDescription}</p>
        </div>
        <div className="col-4">
          <p className="mt-3">{listItem.Price}</p>
        </div>

      </div>
    </div>
  );
}
