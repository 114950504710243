import React from "react";


export default function About() {
  return (
    <div className="pageContent about">
  
      <h2 className="heading text-center">About Trivologist</h2>
      <br />
      <br />
<div className="row align-middle text-center">
<div className="col-md-8 offset-md-2 col-sm-10 offset-md-1">
<p>
  Just a group of nerds who love to learn. Everything from 
  <a rel="noopener noreferrer" target="_blank" href="https://en.wikipedia.org/wiki/Punic_Wars"> Punic wars</a> to <a target="_blank" rel="noopener noreferrer" href="https://www.tate.org.uk/art/art-terms/p/pop-art">Pop art</a>, <a rel="noopener noreferrer" href="https://en.wikipedia.org/wiki/Biology">Biology</a> to <a href="https://en.wikipedia.org/wiki/BASIC" target="_blank" rel="noopener noreferrer">BASIC</a>, <a href="https://en.wikipedia.org/wiki/Cricket" rel="noopener noreferrer" target="_blank">Cricket</a> to <a href="https://www.delish.com/content/cocktail-recipes/" rel="noopener noreferrer" target="_blank">Cocktails</a> and <a href="https://en.wikipedia.org/wiki/List_of_Academy_Award-winning_films" rel="noopener noreferrer" target="_blank">Oscars</a> to <a href="https://en.wikipedia.org/wiki/World_Ocean" rel="noopener noreferrer" target="_blank">Oceans</a>, we just love knowing stuff!
<br />
<br />
<br />
Knowledge is no burden to carry, so join us in our quest to fill our minds with <span style={{"text-decoration":"line-through"}}>useless</span> trivia! 
</p>
</div>



</div>

    </div>
  );
}
