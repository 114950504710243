import React, { Component } from "react";
import { authenticationService } from "../../services/Authentication.js"


export default class SingleItem extends Component{

  constructor(props) {
    super(props);
  var cu = authenticationService.currentUserValue;

  var auth = (cu != null);


    this.allCats = this.props.listItem.Categories.split(";"); 
    this.firstCat = this.allCats[0];
    this.hidden = {
            display:"none"
          };


  this.state = {isHidden: true,
    list: [],
    question: this.props.listItem.Question,
    questionid: this.props.listItem.QuestionID,
     isAnswered:false,       
    currentUser: cu,
      authenticated: auth
  }

  
  this.saveQuestionData = this.saveQuestionData.bind(this);
  this.checkAnswer = this.checkAnswer.bind(this);

};

  //This happens if someone clicks the reference button
  gotoReference= function(e){
   
    //e.preventDefault;
    //reset animation
    e.target.classList.remove('animate');
    
    e.target.classList.add('animate');

    setTimeout(() => {
    //  this.setState((prevState)=>({isHidden: !prevState.isHidden}))
    }, 450);
    window.open(this.props.listItem.Reference,'_blank');

  }

  
  //This all happens once the See Answers button is clicked
  toggleHidden = function(e){

    //e.preventDefault;
    //reset animation
    e.target.setAttribute("disabled", "disabled");

    e.target.classList.remove('animate');
    
    e.target.classList.add('animate');

    setTimeout(() => {
      this.setState((prevState)=>({isHidden: !prevState.isHidden}));
      
      if(this.props.listItem.QuestionType === "Freetext"){
        this.setState((prevState)=>({isAnswered: true}))
      }
    var answers = Array.from(document.getElementById("answerContainer"+this.props.listItem.id).children);

    var random = Math.ceil(Math.random() * answers.length);
    answers.forEach(answer => {

      answer.classList.add("bubbly-button-" + random);
      random = random + 1
      if (random > answers.length)
      {
        random = 1;

      }
        });
    }, 750);

  }

  //This happens when any anwswer button is clicked
  checkAnswer = function(e){
if(e.target.innerText.indexOf(this.props.listItem.Answer) !== -1)
{

  e.target.classList.add('bubbly-button-right');
    e.target.classList.remove('animate');
    
    e.target.classList.add('animate');

    setTimeout(() => {
      //this.setState((prevState)=>({isHidden: !prevState.isHidden}))
      this.setState((prevState)=>({isAnswered: true}))
    }, 750);
    e.target.classList.add('correct');
    this.props.manageAnswer(this.props.listItem.id, 1);
    this.saveQuestionData(true);
  }
  else{
    e.target.classList.add('bubbly-button-wrong');

    e.target.classList.remove('animate');
    
    e.target.classList.add('animate');

    setTimeout(() => {

    }, 750);

    e.target.classList.add('notRight');
    this.props.manageAnswer(this.props.listItem.id, 0);
    this.saveQuestionData(false);
  }
  
  }


  saveQuestionData(correct){
console.log("Saving Data!")
    if(this.state.currentUser)
    {
      console.log("Writing User Results Question");
        fetch("/api/AddUserScoreQuestion", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            QuestionID: this.props.listItem.id,
            UserID: this.state.currentUser.id,
            Score: correct,
            Time: this.state.stopwatch
          })
        })
          .then(response => {
           console.log("response");
            console.log(response);
            if (!response.ok) {
              
              throw Error(response.statusText);
            }
            return response.json();
          })
          .then(
            result => 
            {
              console.log("result2")
              console.log(result)
              if(result === "User already answered")
              {
                this.props.openWarning("The database already has your answer for today :)")
              }
              else
              {
                console.log("Setting user state")
                var newUser = this.state.currentUser
                newUser.Streak = result.streak
                console.log("newUser");
                console.log(newUser);
                this.setState({ currentUser: newUser})
                authenticationService.login(newUser);
              }
            }
          )
          .catch(error =>
          {
            console.log(error.message)
            this.props.openWarning(error.message)
            
          })
          console.log("Saved Data!")
      }
      else
      {
        console.log("No logged in user")
        console.log(this.state)
      }
    
  }




  componentDidMount(){

    window.scrollTo(0, 0)
    var moment = require('moment');


    //Sort out the Twitter Share Button
    const anchor = document.createElement("a");
    anchor.setAttribute("class", "twitter-share-button");
    anchor.setAttribute("data-hashtags", "trivia");
    anchor.setAttribute("data-url", "https://trivologist.com/" + moment(this.props.listItem.DateUsed).format("DD-MM-YYYY"));
    anchor.setAttribute("data-text", this.props.listItem.Question);
    anchor.setAttribute("data-related", "trivologist");
    anchor.setAttribute("data-show-count", "false");
    anchor.setAttribute("href", "https://twitter.com/share?ref_src=twsrc%5Etfw");
    anchor.innerHTML = "<i className='fab fa-twitter'></i>Tweet";
    document.getElementsByClassName("twitter-embed " + this.props.listItem.id)[0].appendChild(anchor);

    const script = document.createElement("script");
    script.setAttribute("src", "https://platform.twitter.com/widgets.js");
    document.getElementsByClassName("twitter-embed " + this.props.listItem.id)[0].appendChild(script);

  }
       
    //Adds the ability to share the link
      shareLinkWithShareDialog() {
document.getElementById("shareButton").style.display = "none";
document.getElementById("loading").style.display = "inline";

var URL = "https://trivologist.com/";

console.log("Item URL" + URL);

        window.FB.ui(
                {
                  method: 'share',
                  href: URL,
                  quote: this.props.listItem.Question
                },
                // callback
                function(response) {
                  if (response && !response.error_message) {
                    console.log('Posting completed.');
                  } else {
                     console.log('Error while posting.');
                  }
                  document.getElementById("shareButton").style.display = "inline";
document.getElementById("loading").style.display = "none";
                }
                
              );

};




  render(){
    

   return(

    
      <div className={"m-2 my-2 questionContainer " + this.firstCat}>
<p className="catIcon">



{this.props.listItem.Categories.startsWith('Entertainment') &&
            <i className="fas fa-compact-disc align-middle"></i>
   }
    {this.props.listItem.Categories.startsWith('Sport') &&
            <i className="fas fa-volleyball-ball align-middle"></i>
   }
    {this.props.listItem.Categories.startsWith('Technology') &&
            <i className="fas fa-microchip align-middle"></i>
   }
    {this.props.listItem.Categories.startsWith('History') &&
            <i className="fas fa-landmark align-middle"></i>
   }
    {this.props.listItem.Categories.startsWith('Art') &&
            <i className="fas fa-palette align-middle"></i>
   }
    {this.props.listItem.Categories.startsWith('Food') &&
            <i className="fas fa-drumstick-bite align-middle"></i>
   }
    {this.props.listItem.Categories.startsWith('Geography') &&
            <i className="fas fa-globe-asia align-middle"></i>
   }
 {this.props.listItem.Categories.startsWith('Science') &&
            <i className="fas fa-flask align-middle"></i>
   }
    {this.props.listItem.Categories.startsWith('General') &&
            <i className="fas fa-lightbulb align-middle"></i>
   }
<span className="catName align-middle">{this.firstCat}</span>
        </p>

      <p className="fb-quotable">
            <strong>{this.props.listItem.Question}</strong> 
           
            </p>  
            <div>
{this.state.isHidden && 
<button className="bubbly-button bubbly-button-standard" onClick={(e) => {
     this.toggleHidden(e)
}}>
Had a guess? Show potential answers...
</button>
}

            {!this.state.isHidden && 
            <div>
              


              {this.props.listItem.QuestionType === 'MultiChoice' && 

<div className="answerContainer row" id={"answerContainer" + this.props.listItem.id}>
<div className="col-xl-3 col-lg-6 col-12">
<button className="bubbly-button" onClick={(e) => {
     this.checkAnswer(e)
}}>{this.props.listItem.Answer}</button>
</div>


<div className="col-xl-3 col-lg-6 col-12 marginauto">
<button className="bubbly-button" onClick={(e) => {
     this.checkAnswer(e)
}}>{this.props.listItem.Wronganswer1}</button>
</div>


<div className="col-xl-3 col-lg-6 col-12 marginauto">
<button className="bubbly-button" onClick={(e) => {
     this.checkAnswer(e)
}}>{this.props.listItem.Wronganswer2}</button>
</div>


<div className="col-xl-3 col-lg-6 col-12 marginauto">
<button className="bubbly-button" onClick={(e) => {
     this.checkAnswer(e)
}}>{this.props.listItem.Wronganswer3}</button>
</div>
</div>
  }

{this.props.listItem.QuestionType === 'YesNo' && 

<div className="answerContainer row" id={"answerContainer" + this.props.listItem.id}>
<div className="col-6">
<button className="bubbly-button" onClick={(e) => {
     this.checkAnswer(e)
}}>{this.props.listItem.Answer}</button>
</div>


<div className="col-6">
<button className="bubbly-button" onClick={(e) => {
     this.checkAnswer(e)
}}>{this.props.listItem.Wronganswer1}</button>
</div>
</div>
  }

{this.props.listItem.QuestionType === 'Freetext' && 
<div className="freetextAnswer text-center" id={"answerContainer" + this.props.listItem.id}>
{this.props.listItem.Answer}
</div>

  }


</div>
  
  }

{this.state.isAnswered &&
<div>
            {this.props.listItem.Moreinfo && 
<div className="moreInfo" dangerouslySetInnerHTML={{__html: (this.props.listItem.Moreinfo)}} />

  }
  <div className="learnContainer" >
<button onClick={(e) => {
     this.gotoReference(e)
}} className="bubbly-button-right bubbly-button learnMore">Learn more here</button>
</div>
</div>
  }
          

<div className="shareRow">

<button id="shareButton" className="btn-fbshare"  onClick={this.shareLinkWithShareDialog.bind(this)}><i className="fab fa-facebook-f"></i>Share</button>

<i className="fas fa-spinner fa-spin" id="loading" style={this.hidden}></i>
      <span className={"twitter-embed " + this.props.listItem.id}></span>


</div>
      </div>
      </div>

   )
  }


 }