import React, { Component } from "react";
import { authenticationService } from '../../services/Authentication.js'



export default class User extends Component {


  constructor(props, context) {
    super(props);
    this.state = {
      list: []
    };


    var cu = authenticationService.currentUserValue;


    if(cu)
    {
    this.state = {
      user: cu,
      authenticated: true,
      showMenu: false,
      showModal: false,
    }
    }
    else
    {
      
    }
  };

  getData(){
console.log("Getting User Profile Data!")
if(this.state.user)
{
  console.log("Getting User Profile Data!")
    fetch("/api/GetUserStats?id=" + this.state.user.id)
      .then(response => {
       console.log("profile response ya fucking cunt");
        console.log(response);
        if (!response.ok) {
          console.log(response.statusText)
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then(json =>
        {
          console.log("json")
          var newUser = this.state.user
          newUser.Streak = json.Streak
          newUser.BestStreak = json.Beststreak
          newUser.allCorrect = json.allCorrect
          newUser.allTotal = json.allTotal

          newUser.SportCorrect = json.SportCorrect
          newUser.SportTotal = json.SportTotal

          newUser.ScienceCorrect = json.ScienceCorrect
          newUser.ScienceTotal = json.ScienceTotal

          newUser.GeographyCorrect = json.GeographyCorrect
          newUser.GeographyTotal = json.GeographyTotal

          newUser.HistoryCorrect = json.HistoryCorrect
          newUser.HistoryTotal = json.HistoryTotal

          newUser.EntertainmentCorrect = json.EntertainmentCorrect
          newUser.EntertainmentTotal = json.EntertainmentTotal

          newUser.FoodCorrect = json.FoodCorrect
          newUser.FoodTotal = json.FoodTotal

          newUser.TechnologyCorrect = json.TechnologyCorrect
          newUser.TechnologyTotal = json.TechnologyTotal

          newUser.GeneralCorrect = json.GeneralCorrect
          newUser.GeneralTotal = json.GeneralTotal

          newUser.ArtCorrect = json.ArtCorrect
          newUser.ArtTotal = json.ArtTotal


          console.log("newUser");
          console.log(newUser);
          this.setState({ currentUser: newUser})
          authenticationService.login(newUser);

        })

  }
}
  
    componentDidMount() {
      //Get all List Items
      this.getData()
  
    }
  
    render() {
      /*const {
        list
      } = this.state;
      */
  
  
    return (
      <div className="pageContent about">
  
      <h2 className="heading text-center">Welcome {this.state.user.name}</h2>
      <br />
      <br />

      <div className="row align-middle text-center offset-2 col-10 offset-md-1">
      <div className="col-4">
Current Streak: {this.state.user.Streak}
</div>
<div className="col-4">
Best Streak: {this.state.user.BestStreak}
</div>
<div className="col-4">
Total Questions: {this.state.user.allCorrect}/{this.state.user.allTotal} - {(this.state.user.allTotal) && this.state.user.allTotal !==0 ?Math.round(this.state.user.allCorrect/this.state.user.allTotal*100): 0}%
</div>


      </div>
<div className="row align-middle text-center offset-2 col-10 offset-md-1">
<div className="col-4 Entertainment">
<p className="catIcon">
<i className="fas fa-compact-disc align-middle"></i>
<span className="catName align-middle">Entertainment</span>

<br /><br />
{this.state.user.EntertainmentCorrect}/{this.state.user.EntertainmentTotal} - {(this.state.user.EntertainmentTotal) && this.state.user.EntertainmentTotal !==0 ?Math.round(this.state.user.EntertainmentCorrect/this.state.user.EntertainmentTotal*100): 0}%
</p>
</div>
<div className="col-4 Sport">
<p className="catIcon">
            <i className="fas fa-volleyball-ball align-middle"></i>
            <span className="catName align-middle">Sport</span>
            
            <br /><br />
            {this.state.user.SportCorrect}/{this.state.user.SportTotal} - {(this.state.user.SportTotal) && this.state.user.SportTotal !==0 ?Math.round(this.state.user.SportCorrect/this.state.user.SportTotal*100): 0}%
            </p>
            </div>
<div className="col-4 Technology">
<p className="catIcon">
            <i className="fas fa-microchip align-middle"></i>
            <span className="catName align-middle">Technology</span>
            <br /><br />

            {this.state.user.TechnologyCorrect}/{this.state.user.TechnologyTotal} - {(this.state.user.TechnologyTotal) &&this.state.user.TechnologyTotal !==0 ? Math.round(this.state.user.TechnologyCorrect/this.state.user.TechnologyTotal*100) : 0}%

            </p>
            </div>
<div className="col-4 History">
<p className="catIcon">
            <i className="fas fa-landmark align-middle"></i>
            <span className="catName align-middle">History</span>
            <br /><br />
            {this.state.user.HistoryCorrect}/{this.state.user.HistoryTotal} - {(this.state.user.HistoryTotal) && this.state.user.HistoryTotal !==0 ?Math.round(this.state.user.HistoryCorrect/this.state.user.HistoryTotal*100): 0}%
            </p>
            </div>
<div className="col-4 Art">
<p className="catIcon">
            <i className="fas fa-palette align-middle"></i>
            <span className="catName align-middle">Art</span>
            <br /><br />
            {this.state.user.ArtCorrect}/{this.state.user.ArtTotal} - {(this.state.user.ArtTotal) && this.state.user.ArtTotal !==0 ?Math.round(this.state.user.ArtCorrect/this.state.user.ArtTotal*100): 0}%
            </p>
            </div>
<div className="col-4 Food">
<p className="catIcon">
               <i className="fas fa-drumstick-bite align-middle"></i>
               <span className="catName align-middle">Food &amp; Drink</span>
               <br /><br />
               {this.state.user.FoodCorrect}/{this.state.user.FoodTotal} - {(this.state.user.FoodTotal) && this.state.user.FoodTotal !==0 ?Math.round(this.state.user.FoodCorrect/this.state.user.FoodTotal*100): 0}%               </p>
   
               </div>
               <div className="col-4 Geography">

               <p className="catIcon ">
            <i className="fas fa-globe-asia align-middle"></i>
            <span className="catName align-middle">Geography</span>

            <br /><br />
            {this.state.user.GeographyCorrect}/{this.state.user.GeographyTotal} - {(this.state.user.GeographyTotal) && this.state.user.GeographyTotal !==0 ?Math.round(this.state.user.GeographyCorrect/this.state.user.GeographyTotal*100) : 0}%
            </p>
            </div>
<div className="col-4 Science">
<p className="catIcon ">
             <i className="fas fa-flask align-middle"></i>
             <span className="catName align-middle">Science</span>

             <br /><br />
             {this.state.user.ScienceCorrect}/{this.state.user.ScienceTotal} - {(this.state.user.ScienceTotal) && this.state.user.ScienceTotal !==0 ? Math.round(this.state.user.ScienceCorrect/this.state.user.ScienceTotal*100): 0}%
             </p>
             </div>
             <div className="col-4 GeneralKnowledge">

             <p className="catIcon">
            <i className="fas fa-lightbulb align-middle"></i>
            <span className="catName align-middle">General Knowledge</span>
<br /><br />
            {this.state.user.GeneralCorrect}/{this.state.user.GeneralTotal} - {(this.state.user.GeneralTotal) && this.state.user.GeneralTotal !==0 ?Math.round(this.state.user.GeneralCorrect/this.state.user.GeneralTotal*100):0}%
            </p>
            </div>

</div>




    </div>
    );
  }


}
