import React, { Component } from 'react';

import { authenticationService } from '../../services/Authentication.js';

export default class ListForm extends Component {
  constructor(props) {
    super(props);
    this.listItem = props.listItem;
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.cu = authenticationService.currentUserValue;
    if(this.listItem)
    {
      this.state = {
        id:this.listItem.id,
        answerField: this.listItem.Answer,
        questionField: this.listItem.Question,
        questionType: this.listItem.QuestionType,
        categories: this.listItem.Categories,
        reference:this.listItem.Reference,
        wronganswer1: this.listItem.Wronganswer1,
        wronganswer2: this.listItem.Wronganswer2,
        wronganswer3: this.listItem.Wronganswer3,
        moreinfo: this.listItem.Moreinfo,
        status: this.listItem.Status,
        difficulty: this.listItem.Difficulty,
        dateused: this.listItem.DateUsed,
        currentUser: this.cu
      }
    }
    else
    {
    this.state = {
      id:"",
      questionField: "",
      answerField: "",
      categories: "",
      reference: "",
      wronganswer1: "",
      wronganswer2: "",
      wronganswer3: "",
      moreinfo:"",
      dateused:"",
      currentUser: this.cu
    }
  }
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit(e) {
    e.preventDefault();
    var qt = "MultiChoice";
    if(this.state.questionType !== "")
    {
      qt = this.state.questionType ;
    }


    let status = 'Draft'; 
    if(this.state.status !== "")
    {
      status = this.state.status;
    }
    if(this.state.currentUser.role === "admin")
    {
      status = 'Published';
    }
    console.log("editing: " + this.state.id)
    this.props.onAddListItem(this.state.questionField, this.state.answerField, this.state.categories, this.state.reference, this.state.wronganswer1, this.state.wronganswer2, this.state.wronganswer3, this.state.moreinfo, qt, this.state.currentUser.email, status, this.state.id, this.state.dateused, this.state.difficulty);
    this.setState({questionField: ""});
    this.setState({answerField: ""});
    this.setState({categories: ""});
    this.setState({reference: ""});
    this.setState({wronganswer1: ""});
    this.setState({wronganswer2: ""});
    this.setState({wronganswer3: ""});
    this.setState({moreinfo: ""});
    this.setState({dateused: ""});
    this.setState({questionType: ""});
    this.setState({status: ""});
    this.setState({difficulty: ""});
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div>
        <div className="input-group my-3">
        <input
          type="text"
          onChange={this.handleChange}
          value={this.state.questionField}
          name="questionField"
          className="form-control"
          placeholder="Add Question here..."
          aria-label="Add Question here..."
        />
        </div>



        <div className="input-group my-3">
         <input
          type="text"
          onChange={this.handleChange}
          value={this.state.answerField}
          name="answerField"
          className="form-control"
          placeholder="Add Answer here..."
          aria-label="Add Answer here..."
        />
        </div>
        <div className="input-group my-3">
         <input
          type="text"
          onChange={this.handleChange}
          value={this.state.categories}
          name="categories"
          className="form-control"
          placeholder="Add Categories here..."
          aria-label="Add Categories here..."
        />
         <input
          type="text"
          onChange={this.handleChange}
          value={this.state.reference}
          name="reference"
          className="form-control"
          placeholder="Add Reference here..."
          aria-label="Add Reference here..."
        />
</div>

<div className="input-group my-3">
         <input
          type="text"
          onChange={this.handleChange}
          value={this.state.wronganswer1}
          name="wronganswer1"
          className="form-control"
          placeholder="Wrong Answer 1"
          aria-label="Wrong Answer 1"
        />
         <input
          type="text"
          onChange={this.handleChange}
          value={this.state.wronganswer2}
          name="wronganswer2"
          className="form-control"
          placeholder="Wrong Answer 2"
          aria-label="Wrong Answer 2"
        />
         <input
          type="text"
          onChange={this.handleChange}
          value={this.state.wronganswer3}
          name="wronganswer3"
          className="form-control"
          placeholder="Wrong Answer 3"
          aria-label="Wrong Answer 3"
        />
        </div>
        <div className="input-group my-3">
         <textarea
          type="text"
          onChange={this.handleChange}
          value={this.state.moreinfo}
          name="moreinfo"
          rows="5"
          className="form-control"
          placeholder="More Info"
          aria-label="More Info"
        />
        </div>
        <div className="input-group my-3">

  <select id="questionType" onChange={this.handleChange} name="questionType">
  <option value="MultiChoice">4 Choices</option>
  <option value="YesNo">2 Choices</option>
  <option value="Freetext">Free Text</option>
</select>


<select id="status" onChange={this.handleChange} name="status">
  <option value="Published">Published</option>
  <option value="Draft">Draft</option>
  <option value="Quizonly">Quiz Only</option>
</select>
<select id="difficulty" onChange={this.handleChange} name="difficulty">
  <option value="TBD">TBD</option>
  <option value="Easy">Easy</option>
  <option value="Medium">Medium</option>
  <option value="Hard">Hard</option>
</select>

        </div>
        <button type="submit" className="btn btn-primary ml-2">
          Submit
        </button>

        <p className="mt-3">ID: {this.state.id}</p>
<p className="mt-3">DATE: {this.state.dateused}</p>
<p className="mt-3">Author: {this.state.currentUser.email}</p>

        </div>
      </form>
    );
  }
}