import React, { Component } from "react";
import GetToday from "../GetToday"
import QuizNav from "../Quiz/QuizNav"
import WarningMessage from "../WarningMessage";
import Konami from 'react-konami-code';



export default class Home extends Component  {

  constructor(props) {
    super(props);
    this.state = {
      date: this.props.match.params.date,
      WarningMessageOpen: false,
      WarningMessageText: "",
      yesterdaysDateLink:"",
      yesterdaysDate:"",
      tomorrow:""
    };
    this.handleWarningClose = this.handleWarningClose.bind(this);
    this.handleWarningOpen = this.handleWarningOpen.bind(this);

  }
 
  componentDidMount() {
  this.getDate();
  }

  getDate(){
    this.setState({ date: this.props.match.params.date})

  }


  
  componentDidUpdate(prevProps) {
    window.scrollTo(0, 0)
    // Typical usage (don't forget to compare props):
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.getDate();
      console.log("doachange");
    }
  }

  easterEgg = () => {
    if(new Date().getDate() === "10" && new Date().getMonth() === "1")
      alert("Happy Birthday!");
  }


handleWarningClose() {
  this.setState({
    WarningMessageOpen: false,
    WarningMessageText: ""
  });
}

handleWarningOpen(error){
  this.setState({
    WarningMessageOpen: true,
    WarningMessageText: error
  });
}



  render() { 
    const {
      WarningMessageOpen,
      WarningMessageText
    } = this.state;




    return(
<main id="mainContent" className={'Container'}> 
<div className="row m-0 align-items-center contentContainer" >
  
<div className="col-lg-12 p-0 align-top align-self-start" > 
<GetToday date={this.state.date} />

</div>
      <div className="col-lg-12 p-0 align-top align-self-start" > 
      <QuizNav throwError={this.handleWarningOpen} />

    </div>



</div>

              <WarningMessage
            open={WarningMessageOpen}
            text={WarningMessageText}
            onWarningClose={this.handleWarningClose}
          />

<Konami action={this.easterEgg} code={[69,77,73,76,89]}>

    </Konami>
    </main>
    );
  }
}


