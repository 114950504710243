import { createBrowserHistory } from 'history';

export default createBrowserHistory();


/*
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();
export default {history} 

*/