import React, { Component } from "react";
import CONSTANTS from "../../constants";
import WarningMessage from "../WarningMessage";
import ListForm from "./ListForm";

export default class EditItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      WarningMessageOpen: false,
      WarningMessageText: ""
    };
    this.handleWarningClose = this.handleWarningClose.bind(this);
    this.handleEditListItem = this.handleEditListItem.bind(this);
  }

  handleWarningClose() {
    this.setState({
      WarningMessageOpen: false,
      WarningMessageText: ""
    });
  }

  // Get the sample data from the back end
  componentDidMount() {
    var { id } = this.props.match.params;


    fetch(CONSTANTS.ENDPOINT.LIST + "/" + id)
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then(result => this.setState({ list: result.data }))
      .catch(error =>
        this.setState({
          WarningMessageOpen: true,
          WarningMessageText: `${CONSTANTS.ERROR_MESSAGE.LIST_GET} ${error}`
        })
      );
  }

  handleEditListItem(questionField, answerField, categories, reference, wronganswer1, wronganswer2,wronganswer3, moreinfo, qt, author, status, id, dateused, difficulty) {
    // Warning Pop Up if the user submits an empty message
    if (!questionField) {
      this.setState({
        WarningMessageOpen: true,
        WarningMessageText: CONSTANTS.ERROR_MESSAGE.LIST_EMPTY_MESSAGE
      });
      return;
    }

    fetch(CONSTANTS.ENDPOINT.LIST + "/Edit/" + id, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id:id,
        Question: questionField,
        Answer: answerField,
        Categories: categories,
        Reference:reference,
        Wronganswer1:wronganswer1,
        Wronganswer2:wronganswer2,
        Wronganswer3:wronganswer3,
        Moreinfo:moreinfo,
        Dateused:dateused,
        Difficulty:difficulty,
        Status:status,
        QuestionType:qt
      })
    })
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        this.props.history.push('/List')
      })
      .catch(error =>
        this.setState({
          WarningMessageOpen: true,
          WarningMessageText: `${CONSTANTS.ERROR_MESSAGE.LIST_ADD} ${error}`
        })
      );
  }

  render() {
    const {
      list,
      WarningMessageOpen,
      WarningMessageText,
    } = this.state;
    

    
  const listItems = list.map((listItem) =>
    // Correct! Key should be specified inside the array.
    <ListForm
    listItem={listItem}
    key={listItem}
    onAddListItem={this.handleEditListItem}
  />

  );
    return (
      <main id="mainContent" className="container">
        <div className="row">
          <div className="col-12 p-0">
          {listItems}

          </div>

          <WarningMessage
            open={WarningMessageOpen}
            text={WarningMessageText}
            onWarningClose={this.handleWarningClose}
          />
        </div>
      </main>
    );
  }
}
