import React, { Component } from "react";
import  {Link} from "react-router-dom";

export default class Yesterday extends Component{

  constructor(props) {
    super(props);
    this.state = {
      date:"",
      dateLink:"/Question",
      icon: ""
    };
  }
  
  render(){
   return(
           <div id="link" className="newDate">

             {this.props.date === "You have reached the beginning of time" ? (
             <a href="http://www.hawking.org.uk/the-beginning-of-time.html"  rel="noopener noreferrer" target="_blank">

{this.props.date}
             </a>
             ) : (

             <Link to={this.props.dateLink + " "}>

             <i className={"fas " + this.props.icon + " arrow"} ></i>


<div>
             {this.props.date}
             </div>
             </Link>
)}
             
                </div>
   )
  }
 }