import React from "react";


export default function DataDeletion() {
  return (
    <div className="policy pageContent">
    <h1>Data Deletion instructions for Trivologist</h1>

    <p>At Trivologist we take data privacy of our users very seriously. </p>

    <p>If you would like to receive a report on the personal data we have captured for you (such as name, email address, Facebook profile image etc.) please email us at <a href="mailto:trivologist@gmail.com">trivologist@gmail.com</a> with the email subject 'Data Report'</p>
 
    <p>If you would like to request that we delete all personal data we have captured for you, please email us at <a href="mailto:trivologist@gmail.com">trivologist@gmail.com</a> with the email subject 'Data Deletion. Note, this will delete all game information related to your account as well, such as questions answered, category statstics etc.</p>
 

    </div>
  );
}
