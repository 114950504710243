import React from "react";

export default function ListItem(props) {
  const { listItem } = props;
  return (
    <div className="col-12 mb-3 border">
      <div className="row">
        <div className="col-4">
          <p className="mt-3">{listItem.Question}</p>
        </div>
        <div className="col-4">
          <p className="mt-3">{listItem.Answer}</p>
        </div>
        <div className="col-4">
          <p className="mt-3">{listItem.Categories} - {listItem.DateUsed}</p>
        </div>


      </div>
      <div className="row">

      <div className="col-4">
          <p className="mt-3"><a href={listItem.Reference}>Link</a></p>
        </div>
        <div className="col-4">
          <p className="mt-3">{listItem.Moreinfo}</p>
        </div>
        <div className="col-4">
  <p className="mt-3">{listItem.Wronganswer1} - {listItem.Wronganswer2} - {listItem.Wronganswer3}</p>
        </div>

      </div>

      <div className="row">

      <div className="col-1">



<a href={"/Edit/" + listItem.id}><i className="fas fa-edit"></i></a>


   </div>
      </div>
    </div>
  );
}
