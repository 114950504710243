import React, { Component } from "react";
import { Link } from "react-router-dom";
import MyLoginButton from "./LoginButton";
import { authenticationService } from '../../services/Authentication.js'
import { Navbar, NavDropdown, Container } from "react-bootstrap";
import Bowser from "bowser";

//TODO Web Template Studio: Add a new link in the NavBar for your page here.
// A skip link is included as an accessibility best practice. For more information visit https://www.w3.org/WAI/WCAG21/Techniques/general/G1.
export default class TrivNavBar extends Component {

  constructor(props) {
    super(props);     

    this.state = {
      currentUser: null,
      isAdmin: false,
      isContributor: false,
      showCatMenu: false
  };

  console.log(this.state.isAdmin);
  console.log(this.state.isContributor);
};



handleOpen = () => {
  this.setState({ showCatMenu: true })
}

handleClose = () => {
   this.setState({ showCatMenu: false })
}

UNSAFE_componentWillMount(){

  authenticationService.currentUser.subscribe(x => this.setState({
    currentUser: x,
    isAdmin: x && x.role === "admin",
    isContributor: x && x.role === "contributor"
}));


}
 

componentDidMount(){
  var allLinks = document.getElementsByClassName("navTop");
  var navbar = document.getElementsByClassName("navbar");
   if(window.matchMedia("(min-width: 768px)").matches)
   {
  var i;
  for (i = 0; i < allLinks.length; i++) {
    
    var width = navbar[1].clientWidth/allLinks.length;

    allLinks[i].style.width = width + "px";
  }
}
}


  render() {
    const { isAdmin, isContributor } = this.state;
    console.log(isAdmin);
    console.log(isContributor);
const browser = Bowser.getParser(window.navigator.userAgent).getBrowserName();

  return (
    <React.Fragment>
          {
           
      browser === "Internet Explorer" &&
   <div className="browserError" >
     Your browser is unsupported (it's very old!). Please upgrade to a new one such as Chrome or Edge 
   </div>
}
            <nav className="navbar navbar-expand-lg navbar-light justify-content-between">
              <div className="navLogo">
      <Link className="navbar-brand" to="/">
          <img src="/Side_Logo.png" className="logoName" alt="Trivologist logo" />
          
          
         
        </Link>
        <span className="slogan">You learn something new every day!</span>
        </div>

        <Container>



<Navbar expand="lg" collapseOnSelect className="justify-content-start nav-fill navbar-dark">

<Navbar.Toggle className="collapsedMenu" aria-controls="responsive-navbar-nav"  />


<Navbar.Collapse>
<NavDropdown title="Categories" id="collapsible-nav-dropdown" onClick = { this.handleOpen } onMouseEnter = { this.handleOpen }
onMouseLeave = { this.handleClose }
show={ this.state.showCatMenu } className="navTop"
>





<div className="catMenu">
<NavDropdown.Item className="nav-item nav-link" href="/Categories/General">General Knowledge</NavDropdown.Item>
<NavDropdown.Item className="nav-item nav-link" href="/Categories/Art">Art</NavDropdown.Item>
<NavDropdown.Item className="nav-item nav-link" href="/Categories/Entertainment">Entertainment</NavDropdown.Item>
<NavDropdown.Item className="nav-item nav-link" href="/Categories/Food">Food &amp; Drink</NavDropdown.Item>
<NavDropdown.Item className="nav-item nav-link" href="/Categories/Geography">Geography</NavDropdown.Item>
<NavDropdown.Item className="nav-item nav-link" href="/Categories/History">History</NavDropdown.Item>
<NavDropdown.Item className="nav-item nav-link" href="/Categories/Science">Science</NavDropdown.Item>
<NavDropdown.Item className="nav-item nav-link" href="/Categories/Sport">Sport</NavDropdown.Item>
<NavDropdown.Item className="nav-item nav-link" href="/Categories/Technology">Technology</NavDropdown.Item>
</div>
</NavDropdown>

<Link to="/Quiz" className="nav-item navTop nav-link">Quizzes</Link>




<Link to="/About" className="nav-item navTop nav-link">About us</Link>
<Link to="/Contact" className="nav-item navTop nav-link">Contact us</Link>
{/*<Link to="/Blog" className="nav-item nav-link">Blog</Link>*/}
{/*<Link to="/Shop" className="nav-item nav-link">Shop</Link>*/}

</Navbar.Collapse>
</Navbar>
</Container>


        <MyLoginButton />
      </nav>


     {/* <div className="triv-nav border-bottom"></div>*/}
     




      
     
    </React.Fragment>
  );
}


}