import React, { Component } from "react";
import SingleItem from "./SingleItem";
import Countdown from "./Countdown";
import Yesterday from "./Yesterday";
import CONSTANTS from "../../constants";
import {Helmet} from "react-helmet";
import WarningMessage from "../WarningMessage";

export default class GetToday extends Component  {

  constructor(props) {
    super(props);
    this.state = {
      list: [],
      WarningMessageOpen: false,
      WarningMessageText: "",
      yesterdaysDateLink:"",
      yesterdaysDate:"",
      tomorrow:"",
      date:""
    };

    this.openWarning = this.openWarning.bind(this);
    this.handleWarningClose = this.handleWarningClose.bind(this);
  }
  
  componentDidMount() {
    window.scrollTo(0, 0)
    this.changeTheData();
  }


  easterEgg = () => {
    if(new Date().getDate() === "10" && new Date().getMonth() === "1")
      alert("Happy Birthday!");
  }


  
changeTheData(){

var date = this.props.date;
    var d = new Date();
    var timeInt = d.getTime()
    var timeIntHere = timeInt + (d.getTimezoneOffset() *60 * 1000)
    var dateHereinUTC = new Date(timeIntHere);

    var now = dateHereinUTC;



    if (date)
    {
      var parts =date.split('-');
      dateHereinUTC = new Date(parts[2], parts[1]-1, parts[0]);
      d = dateHereinUTC;
           
      if(d > now)
       {
         d = new Date();
         timeInt = d.getTime()
         timeIntHere = timeInt + (d.getTimezoneOffset() *60 * 1000)
         dateHereinUTC = new Date(timeIntHere);
       }


    } 
var tomorrowsDate = new Date(dateHereinUTC.getTime()+(24*60*60*1000))
   var yesterdaysDate = new Date(dateHereinUTC.getTime()-(24*60*60*1000))
   
    var finalDate = dateHereinUTC.getFullYear() +"-" + ("0"+(dateHereinUTC.getMonth()+1)).slice(-2)+"-"+("0" + dateHereinUTC.getDate()).slice(-2);
   var yesterdaysDateLink = ("0" + (yesterdaysDate.getDate())).slice(-2) + "-" + ("0" + (yesterdaysDate.getMonth()+1)).slice(-2) + "-" + yesterdaysDate.getFullYear()
    var tomorrowsDateLink = ("0" + (tomorrowsDate.getDate())).slice(-2) + "-" + ("0" + (tomorrowsDate.getMonth()+1)).slice(-2) + "-" + tomorrowsDate.getFullYear()
    yesterdaysDate = yesterdaysDate.toDateString();

    if( dateHereinUTC <= new Date('2019','10','1')  )
    {
      yesterdaysDate = "You have reached the beginning of time";
      
    }

    console.log(finalDate);
    fetch("/api/GetQuestion?date="+finalDate)  
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then(
        result => 
        {
            if(result.length === 0)
          {
            console.log("No items");
            throw new Error("no items found");
          }
          console.log("r333asasesult")
          console.log(result.data)
            this.setState({ list: result.data, now:now.toDateString() ,yesterdaysDate: yesterdaysDate, yesterdaysDateLink: yesterdaysDateLink, tomorrowsDate: tomorrowsDate.toDateString(), tomorrowsDateLink: tomorrowsDateLink })
          }
      )
      .catch(error =>
        this.setState({
          WarningMessageOpen: true,
          WarningMessageText: `${CONSTANTS.ERROR_MESSAGE.LIST_GET} ${error}`
        })
      );
}

componentDidUpdate(prevProps) {
  // Typical usage (don't forget to compare props):
  if (this.props.date !== prevProps.date) {
    this.changeTheData()
  }
}

handleWarningClose() {
  this.setState({
    WarningMessageOpen: false,
    WarningMessageText: ""
  });
}

openWarning(messageText)
{
  this.setState({
    WarningMessageOpen: true,
    WarningMessageText: messageText
  });

}


      //This happens when any anwswer button is clicked
      checkAnswer = function(Answer, isCorrect){
        console.log("You got " + Answer + " " + isCorrect);
        
      }

  render() { 
    const {
      list,
      WarningMessageOpen,
      WarningMessageText
    } = this.state;


    

    return(

<div className="row m-1 my-2 align-items-center" >



      <div className="col-md-8 order-md-2" > 
          {list.map(listItem => (
       <div className={"question "} key="a" > 


      {document.body.className = ""}
      {document.body.classList.add(listItem.Categories.includes(';') ? listItem.Categories.substring(0,listItem.Categories.indexOf(';')).replace(/ /g,'').replace('&','') : listItem.Categories.replace(/ /g,'').replace('&',''))}


     <SingleItem
        key={listItem.id}
        listItem={listItem}
        location={this.props.location}
        manageAnswer={this.checkAnswer}
        openWarning = {this.openWarning}
        
      />
      <Helmet>
      <meta name="description"  content={listItem.Question} />
      <meta property="og:title" content="Trivologist - Learn something new every day" />
      {listItem.MediaURL ?
      (
      <meta property="og:image" content={listItem.MediaURL} />
      ) : (
<meta property="og:image" content="https://trivologist.com/1024.png" /> 
      )}
      <meta property="og:description"  content={listItem.Question} />
    </Helmet>
      </div>
      
    ))}

    </div>

    <div className="col-md-2 col-6 order-md-1">
         
    <Yesterday icon="anotherDayIcon fa-angle-double-left" date={this.state.yesterdaysDate} dateLink={this.state.yesterdaysDateLink} />
      
              </div>


    <div className="col-md-2 order-md-last col-6">
      {new Date(this.state.tomorrowsDate) > new Date(this.state.now) ? (
        <Countdown />
        
        ) : (
        <div>
            <Yesterday icon="anotherDayIcon fa-angle-double-right" date={this.state.tomorrowsDate} dateLink={this.state.tomorrowsDateLink} />
        </div>
)}


</div>
<WarningMessage
            open={WarningMessageOpen}
            text={WarningMessageText}
            onWarningClose={this.handleWarningClose}
          />

</div>
    );
  }
}


