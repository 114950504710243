import React, {Component} from 'react';
import CONSTANTS from "../../constants";



export default class Categories extends Component  {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      sort: "Count"
    };


  }


  //This all happens once the See Answers button is clicked
  toggleSort = function(e){

      if(this.state.sort === "Count")
      {
      this.setState({sort: "Name"});
    }
    else
    {
      this.setState({sort: "Count"});

    }
      
    this.getData();
  }

      getData = function(e){
        fetch(CONSTANTS.ENDPOINT.CATEGORIES)
        .then(response => {
          if (!response.ok) {
            throw Error(response.statusText);
          }
          return response.json();
        })
        .then(result => {
         
        const mediaTypes = result // get all media types
        .filter((mediaType, index, array) => array.indexOf(mediaType) === index), // filter out duplicates
      
      counts = mediaTypes
        .map(mediaType => ({
          type: mediaType,
          count: result.filter(item => item === mediaType).length
        })); 
        if(this.state.sort === "Name")
        {
        counts.sort(((a, b) => (a.type > b.type) ? 1 : -1));
        }
        else{
          counts.sort(((a, b) => (a.count > b.count) ? -1 : 1));
        }
        counts.filter((item) => item.type !== "");
          this.setState({ categories: counts.filter((item) => item.type !== "")});
      })
          .catch(error =>
            this.setState({
              WarningMessageOpen: true,
              WarningMessageText: `${CONSTANTS.ERROR_MESSAGE.LIST_GET} ${error}`
            }))}




            UNSAFE_componentWillMount()
{
  this.getData()
  }

  render() {
    const {
      categories
    } = this.state;
  return (
    <div className="col-12 mb-3 border">
      <button onClick={(e) => {
     this.toggleSort(e)
}}>Sort By Name</button>
      <button onClick={(e) => {
     this.toggleSort(e)
}}>Sort By Count </button>
<br />
<h4>Categories</h4>

      {categories.map(listItem => (
      <p className={listItem.type} key={listItem.type}>{listItem.type}: {listItem.count}</p>
      ))}

      
    </div>
  );
}
}
