import React, {Component} from 'react';

import { authenticationService } from '../../services/Authentication.js'
import SelectableQuestions from './SelectableQuestions'

export default class NewQuiz extends Component {
  constructor(props) {
    super(props);
    this.listItem = props.listItem;
    this.handleChange = this.handleChange.bind(this);
    this.handleFile = this.handleFile.bind(this);
    this.submitFile = this.submitFile.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.cu = authenticationService.currentUserValue;

    this.saveSelectedQuestions = this.saveSelectedQuestions.bind(this);


    if(this.listItem)
    {
      this.state = {
        id:this.listItem.id,
        title: this.listItem.Title,
        categories: this.listItem.Categories,
        description: this.listItem.Description,
        byline: this.listItem.Byline,
        author: this.cu,
        mediaurl: this.listItem.MediaURL,
        mediatext: this.listItem.MediaText,
        quiztype: this.listItem.QuizType,
        questions: [],
        sampleFile: this.listItem.sampleFile,
        fileUploaded:false
      }
    }
    else
    {
    this.state = {
      id:"",
      title:"",
      categories: "",
      description: "",
      byline: "",
      author: "",
      mediaurl: "",
      mediatext: "",
      quiztype: "",
      questions: [],
      sampleFile:"",
      fileUploaded:false
    }
  }
  }


  UNSAFE_componentWillMount(){

    authenticationService.currentUser.subscribe(x => this.setState({
      currentUser: x,
      isAdmin: x && x.role === "admin",
      isContributor: x && x.role === "contributor"
  }));

}
  handleFile(e) {
    this.setState({ [e.target.name]: e.target.files[0] });
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  submitFile()
  {
      const fd = new FormData();
      fd.append("file", this.state.sampleFile)
    const fileName = this.state.sampleFile.name;
    fetch("/upload", {
      method: "POST",
     // headers: { "Content-Type": "multipart/form-data" },
      body: fd
    })
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        this.setState({mediaurl: "/Media/" + fileName});
        this.setState({fileUploaded: true});
      })
      .catch(error =>
        {
          console.log(error);
        this.props.throwError(error.message)
      }
        );
      
  }

  handleSubmit(e, req) {

    var cont = window.confirm("Are you sure?")

if(cont)
{
    e.preventDefault();
    var qt = "online";
    if(this.state.quiztype !== "")
    {
      qt = this.state.quiztype ;
    }
    let status = 'Draft'; 
    if(this.props.currentUser.role === "admin")
    {
      status = 'Published';

    }
    this.props.onAddQuiz(this.state.title, this.state.description, this.state.categories, this.props.currentUser.email,  this.state.mediaurl, this.state.mediatext, qt, this.state.questions, this.state.sampleFile, status, this.state.byline);
    this.setState({id: ""});
    this.setState({title: ""});
    this.setState({description: ""});
    this.setState({byline: ""});
    this.setState({categories: ""});
    this.setState({author: ""});
    this.setState({mediaurl: ""});
    this.setState({mediatext: ""});
    this.setState({questions: []});
  }
}

  saveSelectedQuestions(qs){
    this.setState({questions: qs});

  }

  render() {
    return (
      <form >
        <div>
        <h4>New Quiz</h4>
<div className="row">
  <div className="col-6">

        <div className="input-group my-3">
        <input
          type="text"
          onChange={this.handleChange}
          value={this.state.title}
          name="title"
          className="form-control"
          placeholder="Add title here..."
          aria-label="Add title here..."
        />
        </div>

        <div className="input-group my-3">
        <input
          type="text"
          onChange={this.handleChange}
          value={this.state.categories}
          name="categories"
          className="form-control"
          placeholder="Add categories here..."
          aria-label="Add categories here..."
        />
        </div>



        <div className="input-group my-3">
         <input
          type="text"
          onChange={this.handleChange}
          value={this.state.description}
          name="description"
          className="form-control"
          placeholder="Add description here..."
          aria-label="Add description here..."
        />
        </div>

        <div className="input-group my-3">
         <input
          type="text"
          onChange={this.handleChange}
          value={this.state.byline}
          name="byline"
          className="form-control"
          placeholder="Add byline here..."
          aria-label="Add byline here..."
        />
        </div>




       
        {!this.state.fileUploaded ? (
          <div>
        <input type="file" onChange={this.handleFile} name="sampleFile" /> 
        <button type="button" onClick={this.submitFile}>Upload</button>
        </div>
        ) : (
              <div>File Uploaded {this.state.sampleFile.name}</div>
            
        )}

        <div className="input-group my-3">
         <input
          type="text"
          onChange={this.handleChange}
          value={this.state.mediaurl}
          name="mediaurl"
          className="form-control"
          placeholder="Add mediaurl here..."
          aria-label="Add mediaurl here..."
        />
        </div>

        <div className="input-group my-3">
         <input
          type="text"
          onChange={this.handleChange}
          value={this.state.mediatext}
          name="mediatext"
          className="form-control"
          placeholder="Add mediatext here..."
          aria-label="Add mediatext here..."
        />
        </div>


<div>
<input type="radio" id="online" onChange={this.handleChange} name="quiztype" defaultChecked value="online"></input>
  <label htmlFor="online">Online</label>
  <input type="radio" id="trivianight" onChange={this.handleChange} name="quiztype" value="trivianight"></input>
  <label htmlFor="trivianight">Trivia Night</label>
  <input type="radio" id="apponly" onChange={this.handleChange} name="quiztype" value="apponly"></input>
  <label htmlFor="trivianight">App Only</label>
        </div>
        <button type="button" onClick={this.handleSubmit} className="btn btn-primary ml-2">
          Submit
        </button>
 

        <p className="mt-3">ID: {this.state.id}</p>
<p className="mt-3">Author: {this.props.currentUser.email}</p>

        </div>

        <div className="col-6">
          <SelectableQuestions saveQuestions={this.saveSelectedQuestions} throwError={this.throwError} />
        </div>
        </div>


        </div>
      </form>
    );
  }
}