import React, {Component} from 'react';
import CONSTANTS from "../../constants";
import SelectableQuestion from "./SelectableQuestion"


export default class SelectableQuestions extends Component  {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      sort: "Count",
      questions: [],
      searchterms: ""
    };
    this.saveQuestions = this.props.saveQuestions;
    this.doSearch = this.doSearch.bind(this);
    this.getData = this.getData.bind(this);
    this.handleChange = this.handleChange.bind(this)
    this.handleSearch = this.handleSearch.bind(this)


 
  }



  
getData(){
  fetch(CONSTANTS.ENDPOINT.LIST)
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then(
        result => {
            this.setState({ list: result.data });
        }
        )
      .catch(error =>{
       console.log(error);
        this.props.throwError(error.message)
      });
}


  componentDidMount() {
    var input = document.getElementById("questionSearch");

    input.addEventListener("keyup", function(event) {
      // Number 13 is the "Enter" key on the keyboard
      if (event.keyCode === 13) {
        // Cancel the default action, if needed
        event.preventDefault();
        // Trigger the button element with a click
        document.getElementById("quizButton").click();
      }
    });

    //Get all List Items
    this.getData()

  }

  doSearch(){
if(this.state.searchterms.trim() === "")
{
this.getData();
return;

}

    fetch("/api/SearchQuestions/" + this.state.searchterms)
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then(
        result => {
            this.setState({ list: result.data });
        }
        )
      .catch(error =>
        alert(error)
        //this.props.throwError(error.message)
      );

  }

  //This all happens once the See Answers button is clicked
  toggleSort = function(e){

      if(this.state.sort === "Count")
      {
      this.setState({sort: "Name"});
    }
    else
    {
      this.setState({sort: "Count"});

    }
      
    this.getData();
  }

  
  handleSearch(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleChange(e) {
    this.questions = this.state.questions;
    if(this.questions.includes(e.target.value)) 
    {
      let quesIndex = this.questions.findIndex(q => q === e.target.value);
if(quesIndex !== -1)
{
      this.questions.splice(quesIndex, 1);
    }
     // this.questions.pop();

  }
    else
    {
    this.questions.push(e.target.value)

    }
    this.setState({ questions: this.questions });
    this.saveQuestions(this.questions);
  }


  render() {
    const {
      list
    } = this.state;
    


  return (
    <div className="col-md-12 order-md-2" >
      Questions in Quiz: {this.state.questions.length}
      <input type="text" name="searchterms" id="questionSearch" onChange={this.handleSearch}></input><button type="button" id="quizButton" onClick={this.doSearch}>Search</button>
    
    {list.map(listItem => (

      <div className="selectableQuestion" key={listItem.id}> 
    <SelectableQuestion
            handleChange={this.handleChange}
       key={listItem.id}
       listItem={listItem}
       location={this.props.location}
     />

     </div>
   ))}


   </div>
  );
}
}
