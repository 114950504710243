import React, { Component } from "react";
import CONSTANTS from "../../constants";
import { authenticationService } from "../../services/Authentication.js";
import WarningMessage from "../WarningMessage";
import ListForm from "./ListForm";
import ListItem from "./ListItem";

export default class List extends Component {
  constructor(props) {

    super(props);
    this.state = {
      list: [],
      user: [],
      WarningMessageOpen: false,
      WarningMessageText: "",
      date: new Date()
    };
    this.user = authenticationService.currentUserValue;
    this.handleWarningClose = this.handleWarningClose.bind(this);
    this.handleDeleteListItem = this.handleDeleteListItem.bind(this);
    this.handleAddListItem = this.handleAddListItem.bind(this);
  }


  componentDidMount() {
    //Get all List Items
    fetch(CONSTANTS.ENDPOINT.LIST)
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then(result => this.setState({ list: result.data }))
      .catch(error =>
        this.setState({
          WarningMessageOpen: true,
          WarningMessageText: `${CONSTANTS.ERROR_MESSAGE.LIST_GET} ${error}`
        })
      );





  }

  handleWarningClose() {
    this.setState({
      WarningMessageOpen: false,
      WarningMessageText: ""
    });
  }

  handleDeleteListItem(listItem) {
    fetch(`${CONSTANTS.ENDPOINT.LIST}/${listItem.id}`, { method: "DELETE" })
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then(result => {
        let list = this.state.list;
        list = list.filter(item => item.id !== result.id);
        this.setState({ list: list });
      })
      .catch(error => {
        this.setState({
          WarningMessageOpen: true,
          WarningMessageText: `${CONSTANTS.ERROR_MESSAGE.LIST_DELETE} ${error}`
        });
      });
  }

  handleAddListItem(questionField, answerField, categories, reference, wronganswer1, wronganswer2,wronganswer3, moreinfo, questionType, userEmail, id, dateused, status, difficulty) {
    // Warning Pop Up if the user submits an empty message
    if (!questionField) {
      this.setState({
        WarningMessageOpen: true,
        WarningMessageText: CONSTANTS.ERROR_MESSAGE.LIST_EMPTY_MESSAGE
      });
      return;
    }
    if(questionType === "" || questionType === undefined )
    {
      questionType = 'MultiChoice'
    }
    if(status === "" || status === undefined )
    {
      status = 'Published'
    }
    if(difficulty === "" || difficulty === undefined )
    {
      difficulty = 'TBD'
    }
    fetch(CONSTANTS.ENDPOINT.LIST, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        Question: questionField,
        Answer: answerField,
        Categories: categories,
        Reference:reference,
        Wronganswer1:wronganswer1,
        Wronganswer2:wronganswer2,
        Wronganswer3:wronganswer3,
        Moreinfo: moreinfo,
        QuestionType: questionType,
        UserEmail: userEmail,
        Status: status,
        Difficulty: difficulty
      })
    })
      .then(response => {
        console.log("response")
        console.log(response)
        if (!response.status === 0) {
          throw Error(response.message);
        }
        return response.json();
      })
      .then(result => {
        console.log("result")
        console.log(result.body)
        this.setState(prevState => ({
          list: [result.data, ...prevState.list]
        }))
      }
      )
      .catch(error =>
        this.setState({
          WarningMessageOpen: true,
          WarningMessageText: `${CONSTANTS.ERROR_MESSAGE.LIST_ADD} ${error}`
        })
      );
  }


  render() {
    const {
      list,
      date,
      WarningMessageOpen,
      WarningMessageText
    } = this.state;
    return (
      <main id="mainContent" className="container">
        <div className="row">
          <div className="col-12 mt-5 p-0">
            <h3>All Questions</h3>
            <p>Total Questions: {list.length} <br /></p>
            
            <p>Publishable: {list.filter(item => item?.Status === "Published").length} - {list.filter(item => item?.DateUsed?.toString() !== "," && item?.Status === "Published").length} used  -  {list.filter(item => item?.DateUsed?.toString() === "," && item?.Status === "Published").length} remaining. </p>
           
              Next questionless day = {new Date(date.setTime(date.getTime() + list.filter(item => item?.DateUsed?.toString() === "," && item?.Status === "Published").length * 86400000 ))?.toString()}
                  
          </div>
          <div className="col-12 p-0">
            <ListForm
              onAddListItem={this.handleAddListItem}
              currentUser={this.state.user}
            />
          </div>

          <div>
          {list.map(listItem => (
            <ListItem
              key={listItem.id}
              listItem={listItem}
              onDeleteListItem={this.handleDeleteListItem}
            />
            
          ))}
          </div>
          



          <WarningMessage
            open={WarningMessageOpen}
            text={WarningMessageText}
            onWarningClose={this.handleWarningClose}
          />
        </div>
      </main>
    );
  }
}
