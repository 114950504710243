import React, { Component } from "react";
import SingleItem from "../GetToday/SingleItem";
import CONSTANTS from "../../constants";
import WarningMessage from "../WarningMessage";
import { Modal } from "react-bootstrap"
import {Helmet} from "react-helmet";
import QuizNav from "../Quiz/QuizNav"
import { authenticationService } from "../../services/Authentication.js"
import MyLoginButton from "../TrivNavBar/LoginButton"


export default class FullQuiz extends Component  {

  constructor(props) {
    super(props);

    var cu = authenticationService.currentUserValue;

    var auth = (cu != null);

    this.state = {
      list: [],
      quizDeets: [],
      answers: [],
      WarningMessageOpen: false,
      WarningMessageText: "",
      showModal: false,
      timerStart: 0,
      timerTime: 0,
      stopwatch: "",
      currentUser: cu,
      authenticated: auth
    };
    this.hidden = {
      display:"none"
    };

    this.manageAnswer = this.manageAnswer.bind(this);
    this.handleWarningClose = this.handleWarningClose.bind(this);
    this.handleWarningOpen = this.handleWarningOpen.bind(this);
  }

  UNSAFE_componentWillMount(){

    authenticationService.currentUser.subscribe(x => this.setState({
      currentUser: x,

  }));

  }

  getQuizData(id){

    fetch("/api/getQuizQuestions/" + id)
    .then(response => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(
      result => 
      {
          console.log("FullQuiz Data")
          console.log(result.data)
          console.log(result)
          this.setState({ list: result.data});
        }
    )
    .catch(error =>
      this.setState({
        WarningMessageOpen: true,
        WarningMessageText: `${CONSTANTS.ERROR_MESSAGE.LIST_GET} ${error}`
      })
    );
  }


stopTimer = () => {
  this.setState({ timerOn: false });
  clearInterval(this.timer);
};


  startTimer = () => {
    this.setState({
      timerOn: true,
      timerTime: this.state.timerTime,
      timerStart: Date.now() - this.state.timerTime
    });


    this.timer = setInterval(() => {
      this.setState({
        timerTime: Date.now() - this.state.timerStart
      });
    
      let seconds = ("0" + (Math.floor(this.state.timerTime / 1000) % 60)).slice(-2);
      let minutes = ("0" + (Math.floor(this.state.timerTime / 60000) % 60)).slice(-2);
      let hours = ("0" + Math.floor(this.state.timerTime / 3600000)).slice(-2);
  
      this.setState({
        stopwatch : hours+":"+minutes+":"+seconds
      });

    }, 1000);
 };


  componentDidMount() {
    this.startTimer();

    this.changeTheData();
      }
  
changeTheData(){
  var { id } = this.props.match.params;
    fetch("/api/GetQuiz/" + id)  
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then(
        result => 
        {
            this.setState({ quizDeets: result.data});
            this.getQuizData(result.data.id);
          }
      )
      .catch(error =>
        this.setState({
          WarningMessageOpen: true,
          WarningMessageText: `${CONSTANTS.ERROR_MESSAGE.LIST_GET} ${error}`
        })
      );
}


saveQuizData(){

  if(this.state.currentUser)
  {
    console.log("Writing User Results");
      fetch("/api/AddUserScore", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          QuizID: this.state.quizDeets.id,
          UserID: this.state.currentUser.id,
          Score: this.state.answers.filter(answer => answer.isCorrect).length,
          Time: this.state.stopwatch
        })
      })
        .then(response => {
          if (!response.ok) {
            throw Error(response.statusText);
          }
          return response.json();
        })
        .catch(error =>
          this.setState({
            WarningMessageOpen: true,
            WarningMessageText: error.message
          })
        );
    }
  
}

handleClose = () => this.setState({showModal: false});


handleShow ()
{
  this.setState({showModal: true});
}



componentDidUpdate(prevProps) {
  // Typical usage (don't forget to compare props):
  if (this.props.location.pathname !== prevProps.location.pathname) {
    this.changeTheData()
  }
}

handleWarningClose() {
  this.setState({
    WarningMessageOpen: false,
    WarningMessageText: ""
  });
}

handleWarningOpen(error){
  this.setState({
    WarningMessageOpen: true,
    WarningMessageText: error
  });
}

manageAnswer(questionId, isCorrect)
{


  let newAnswers = this.state.answers;
  //if question wasn't already answered
  if(newAnswers.filter(answer => answer.qid === questionId).length === 0)
  {
    newAnswers.push({qid: questionId, isCorrect: isCorrect});
    this.setState({ answers: newAnswers})
  }

  //IF all answers and answered
  if (newAnswers.length === this.state.list.length)
  {
    this.stopTimer();
    this.saveQuizData();
    this.handleShow();
  }

}


shareLinkWithShareDialog() {
  document.getElementById("shareButton").style.display = "none";
  document.getElementById("loading").style.display = "inline";
  
var URL = window.location.href;

  console.log("Item URL " + URL);

  if(URL.indexOf("localhost:3000") !== -1)
    URL = URL.replace('localhost:3000', "trivologist.com");

          window.FB.ui(
                  {
                    method: 'share',
                    href: URL,
//picture: "https://trivologist.com" + this.state.quizDeets.MediaURL,  
                    quote: "I just scored " + this.state.answers.filter(answer => answer.isCorrect).length+"/"+this.state.list.length
                     + " on the " + this.state.quizDeets.Title + " quiz.\r\n\r\nGive it a try!"
                  },
                  // callback
                  function(response) {
                    if (response && !response.error_message) {
                      console.log('Posting completed.');
                    } else {
                       console.log('Error while posting.');
                    }
                    document.getElementById("shareButton").style.display = "inline";
                    document.getElementById("loading").style.display = "none";
                  }
                  
                );
  
  };
  


  render() { 
    const {
      list,
      answers,
      quizDeets,
      showModal,
      WarningMessageOpen,
      WarningMessageText,
      authenticated
    } = this.state;


    return(
<main id="mainContent" className={'Container'}> 
<div className="row m-3 my-2 align-items-center" >
      <div className="col-lg-12 p-10 align-top align-self-start" > 

<div>
<span class="quizTimer">
{this.state.stopwatch}
   </span>

    <img className="quizImageHeader" src={quizDeets.MediaURL} alt={quizDeets.MediaText} ></img>
    <h4>{quizDeets.Title}</h4>
   <div className="quizDescription">{quizDeets.Description}</div>

</div>


          {list.map(listItem => (
       <div className={"question "} key={listItem.id} > 
      {document.body.className = ""}
      {document.body.classList.add(listItem.Categories.includes(';') ? listItem.Categories.substring(0,listItem.Categories.indexOf(';')).replace(/ /g,'').replace('&','') : listItem.Categories.replace(/ /g,'').replace('&',''))}
  
     <SingleItem
        key={listItem.id}
        listItem={listItem}
        location={this.props.location}
        manageAnswer={this.manageAnswer}
      />
      </div>
    ))}


    </div>

      <div className="col-lg-12 align-top align-self-start p-10 quizNav" > 
            
      <QuizNav Number="10" Random throwError={this.handleWarningOpen} />

    </div>
    <Helmet>
            <meta name="description" content={quizDeets.Description} />
            <meta property="og:title" content={quizDeets.Title} />
            <meta property="og:image"  content={"https://trivologist.com" + quizDeets.MediaURL} />
            <meta property="og:description" content={quizDeets.Description} />
            <meta property="og:url" content={"https://trivologist.com" + quizDeets.Title} />

       
            <meta property="twitter:title" content={quizDeets.Title} />
            <meta property="twitter:image"  content={"https://trivologist.com" + quizDeets.MediaURL} />
            <meta property="twitter:description" content={quizDeets.Description} />

          </Helmet>
</div>


<div className="anchorToFooter">
<span className="quizScore"> Current Score: {answers.filter(answer => answer.isCorrect).length} / {list.length}
   </span>
      <h5>{quizDeets.Title}</h5>
      {this.state.stopwatch}
     
   </div>

              <WarningMessage
            open={WarningMessageOpen}
            text={WarningMessageText}
            onWarningClose={this.handleWarningClose}
          />








<Modal show={showModal} onHide={this.handleClose}>
  <Modal.Header closeButton>
    <Modal.Title>Congrats!</Modal.Title>
  </Modal.Header>

  <Modal.Body>
  <div className="modal-body text-center">
        Well done! Your score was {answers.filter(answer => answer.isCorrect).length} / {list.length}
<br />
<br />
You finished the Quiz in {this.state.stopwatch}
<br />
<br />
        
{!authenticated ? (
<div>Login to save your score!
  <br /><MyLoginButton />
  </div>
):(
  <div></div>
)
  }
<br />
<br />
<button id="shareButton" className="btn-fbshare"  onClick={this.shareLinkWithShareDialog.bind(this)}>
  <i className="fab fa-facebook-f"></i>Share your score on Facebook!</button>
  <i className="fas fa-spinner fa-spin" id="loading" style={this.hidden}></i>
    
      </div>
  </Modal.Body>
</Modal>

    </main>
    );
  }
}


