import { BehaviorSubject } from 'rxjs';
const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

export const authenticationService = {
    login,
    logout,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue () { return currentUserSubject.value }
};

function login(user) {
    console.log("User");
    console.log(user);
             localStorage.setItem('currentUser', JSON.stringify(user));
            currentUserSubject.next(user);

            return user;
}

function logout() {
    localStorage.removeItem('currentUser');
    currentUserSubject.next(null);
}

