import React, { Component } from "react";
import SingleProduct from "./SingleProduct";
import CONSTANTS from "../../constants";
import {Helmet} from "react-helmet";
import WarningMessage from "../WarningMessage";

export default class Shop extends Component  {

  constructor(props) {
    super(props);
    this.state = {
      list: [],
      WarningMessageOpen: false,
      WarningMessageText: "",
      date:""
    };

    this.openWarning = this.openWarning.bind(this);
    this.handleWarningClose = this.handleWarningClose.bind(this);
  }
  

  

  componentDidMount() {

  console.log("Thing")

  fetch("/api/GetProducts")  
  .then(response => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  })
  .then(
    result => 
    {
        if(result.length === 0)
      {
        console.log("No items");
        throw new Error("no items found");
      }
      
        this.setState({ list: result.data})
      }
  )
  .catch(error =>
    this.setState({
      WarningMessageOpen: true,
      WarningMessageText: `${CONSTANTS.ERROR_MESSAGE.LIST_GET} ${error}`
    })
  );
}

handleWarningClose() {
  this.setState({
    WarningMessageOpen: false,
    WarningMessageText: ""
  });
}

openWarning(messageText)
{
  this.setState({
    WarningMessageOpen: true,
    WarningMessageText: messageText
  });

}


  render() { 
    const {
      list,
      WarningMessageOpen,
      WarningMessageText
    } = this.state;


    

    return(

<div className="row m-1 my-2 align-items-center" >
Check out the SHOP!


      <div className="col-md-8 order-md-2" > 
          {list.map(listItem => (
       <div className={"question "} key="a" > 


      {document.body.className = ""}


     <SingleProduct
        key={listItem.id}
        listItem={listItem}
        location={this.props.location}
        manageAnswer={this.checkAnswer}
        openWarning = {this.openWarning}
        
      />
     
      </div>
      
    ))}

    </div>
    <Helmet>
      <meta name="description"  content="The Trivologist shop where you can buy Question packs" />
      <meta property="og:title" content="Trivologist - Learn something new every day" />
      <meta property="og:image" content="https://trivologist.com/1024.png" /> 
      <meta property="og:description"  content="The Trivologist shop where you can buy Question packs" />
    </Helmet>

<WarningMessage
            open={WarningMessageOpen}
            text={WarningMessageText}
            onWarningClose={this.handleWarningClose}
          />

</div>
    );
  }
}


